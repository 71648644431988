import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import {
  Box,
  Button,
  Link,
  Portal,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 600,
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    outline: 'none',
    zIndex: 2000,
  },
  action: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
}));

const CookiesNotification = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    Cookies.set('consent', 'true');
    setOpen(false);
  };

  useEffect(() => {
    const consent = Cookies.get('consent');

    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Typography variant='body1' color='inherit'>
          Usamos cookies para permitir que o nossa plataforma funcione
          corretamente, para personalizar nossos conteúdos e anúncios, para
          proporcionar funcionalidades das redes sociais e para analisar o nosso
          tráfego. Também partilhamos informação com os nossos parceiros das
          redes sociais, de publicidade e de analítica sobre o seu uso do nosso
          website:{' '}
          <Link
            component='a'
            color='inherit'
            underline='always'
            href='/terms'
            target='_blank'
          >
            Políticas de privacidade
          </Link>
          .
        </Typography>
        <Box mt={2} display='flex' justifyContent='flex-end'>
          <Button
            onClick={handleClose}
            variant='contained'
            className={classes.action}
          >
            Concordo
          </Button>
        </Box>
      </div>
    </Portal>
  );
};

export default CookiesNotification;
