import React, { createContext, useEffect, useReducer } from 'react';
import _ from 'lodash';

import useParameter from 'src/hooks/useParameter';
import api from 'src/services/api';

const initialState = {
  parameter: null,
  parameters: [],
  error: null,
  status: null,
  loading: false,
  variables: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE_PARAMETER_REQUEST': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'GET_ALL_PARAMETER_SUCCESS': {
      const { parameters } = action.payload;

      return {
        ...state,
        parameters,
        status: 200,
        loading: false,
      };
    }

    case 'UPDATED_PARAMETER_SUCCESS':
    case 'GET_PARAMETER_SUCCESS': {
      const { parameter } = action.payload;
      return {
        ...state,
        parameter,
        status: 200,
        loading: false,
      };
    }

    case 'CREATE_PARAMETER_SUCCESS':
      return {
        ...state,
        parameters: state.parameters.concat(action.payload),
        status: 201,
        loading: false,
      };

    case 'GET_PARAMETER_VARIABLE_SUCCESS': {
      return {
        ...state,
        variables: { ...state.variables, ...action.payload },
        status: 200,
        loading: false,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const ParameterContext = createContext({
  ...initialState,
  getAllParameters: () => Promise.resolve(),
  getParameterByUuid: uuid => Promise.resolve(),
  getParameterByName: paramName => Promise.resolve(),
  createParameter: parameter => Promise.resolve(),
  updateParameter: paramObjec => Promise.resolve(),
});

export const ParameterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getAllParameters = async filtro => {
    dispatch({ type: 'INITIALIZE_LESSON' });

    const { data } = await api.get(`/parameters`);

    dispatch({
      type: 'GET_ALL_PARAMETER_SUCCESS',
      payload: {
        parameters: data,
      },
    });
  };

  const getParameterByUuid = async uuid => {
    dispatch({ type: 'INITIALIZE_LESSON' });
    const { data } = await api.get(`/parameters/${uuid}`);
    dispatch({
      type: 'GET_PARAMETER_SUCCESS',
      payload: {
        parameter: data,
      },
    });
  };

  const getParameterByName = async paramName => {
    dispatch({ type: 'INITIALIZE_LESSON' });

    const { data } = await api.get(`/parameters/find/${paramName}`);

    dispatch({
      type: 'GET_PARAMETER_VARIABLE_SUCCESS',
      payload: {
        [data.name]: data,
      },
    });
  };

  const createParameter = async parameter => {
    dispatch({ type: 'INITIALIZE_LESSON' });

    const { data } = await api.post('/parameters', parameter);

    dispatch({
      type: 'CREATE_PARAMETER_SUCCESS',
      payload: data,
    });
  };

  const updateParameter = async paramObjec => {
    dispatch({ type: 'INITIALIZE_LESSON' });
    const response = await api.put(
      `/parameters/${paramObjec.uuid}`,
      paramObjec
    );
    dispatch({
      type: 'UPDATED_PARAMETER_SUCCESS',
      payload: {
        lessonPlan: response.data,
      },
    });
  };

  return (
    <ParameterContext.Provider
      value={{
        ...state,
        getAllParameters,
        getParameterByUuid,
        createParameter,
        updateParameter,
        getParameterByName,
      }}
    >
      {children}
    </ParameterContext.Provider>
  );
};

export default ParameterContext;
