import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  Slide,
  makeStyles,
  Card,
  Typography,
  CardContent,
  Grid,
  Box,
} from '@material-ui/core';

import ChildEducationIllustration from './illustrations/ChildEducation';
import EarlyYearsIllustration from './illustrations/EarlyYears';
import FinalYearsIllustration from './illustrations/FinalYears';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    borderRadius: theme.spacing(2),
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.secondary.main,
  },
  wrapperTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#fff',
  },
  description: {
    fontSize: 18,
  },
}));

const Transition = forwardRef((props, ref) => {
  return <Slide direction='up' ref={ref} {...props} />;
});

function MoreDetailsModal({ planSelected, isOpen, handleClose }) {
  const classes = useStyles();

  if (!isOpen) return <></>;

  function getIllustration(type) {
    const illustrations = {
      child: ChildEducationIllustration,
      early: EarlyYearsIllustration,
      final: FinalYearsIllustration,
    };

    return illustrations[type];
  }

  return (
    <div>
      <Dialog
        keepMounted
        open={isOpen}
        onClose={handleClose}
        className={classes.root}
        TransitionComponent={Transition}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <div className={classes.container}>
          <Grid className={classes.wrapper}>
            <Grid className={classes.wrapperTitle}>
              <Typography gutterBottom variant='h1'>
                {planSelected.title}
              </Typography>
              <Typography gutterBottom variant='h4'>
                {planSelected.shortDescription}
              </Typography>
            </Grid>

            <Box>{getIllustration(planSelected.type)}</Box>
          </Grid>
          <DialogContent>
            <Card className={classes.content}>
              <CardContent>
                <Typography
                  className={classes.description}
                  variant='body1'
                  color='secondary'
                  component='p'
                  dangerouslySetInnerHTML={{
                    __html: planSelected.fullDescription,
                  }}
                />
              </CardContent>
            </Card>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

MoreDetailsModal.propTypes = {
  planSelected: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    price: PropTypes.number,
    type: PropTypes.oneOf(['early', 'child', 'final']),
    shortDescription: PropTypes.string,
    fullDescription: PropTypes.string,
    navigatePath: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default MoreDetailsModal;
