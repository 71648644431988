export const userStatus = [
  { id: 0, label: 'Active' },
  { id: 1, label: 'Blocked by Password' },
  { id: 2, label: 'Blocked by User' },
  { id: 3, label: 'Inactive' },
  { id: 4, label: 'Blocked by planExpire' },
];

export const userStatusEnum = {
  ACTIVE: 0,
  BLOCKED_BY_PASSWORD: 1,
  BLOCKED_BY_USER: 2,
  INACTIVE: 3,
  BLOCKER_BY_PLAN_EXPIRE: 4,
  BLOCKED_BY_MISCONDUCT: 5,
};

export const userTypes = [
  { id: 1, label: 'Super-Admin' },
  { id: 2, label: 'Admin' },
  { id: 3, label: 'Comercial' },
  { id: 4, label: 'Pedagogo' },
  { id: 5, label: 'Editor' },
  { id: 6, label: 'Diretor' },
  { id: 7, label: 'Coordenador' },
  { id: 8, label: 'Professor' },
];

export const userTypesEnum = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  COMMERCIAL: 3,
  PEDAGOGO: 4,
  EDITOR: 5,
  DIRETOR: 6,
  COORDINATOR: 7,
  PROFESSOR: 8,
};

export const planType = [
  { id: 3, label: 'Plano Ouro' },
  { id: 4, label: 'Plano Gratuito' },
];

export const planTypeEnum = {
  BRONZE: 1,
  PRATA: 2,
  OURO: 3,
  GRATUITO: 4,
};
