import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { getInitials } from 'src/utils/utilityFunctions';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 266,
    height: 244,
    maxHeight: 244,
    padding: theme.spacing(3),
    gap: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `2px solid ${theme.palette.divider}`,
    transition: 'background 0.3s',

    '&:hover': {
      background: theme.palette.grey[50],
      cursor: 'pointer',
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: 9,
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    background: theme.palette.primary.main,
  },
  label: {
    background: '#74D2E7',
    color: '#FFFFFF',
    borderRadius: 4,
    padding: theme.spacing(0.5, 2),
  },
  title: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

function LastAccessItem({ lastAccess }) {
  const classes = useStyles();
  const history = useHistory();

  function handleNavigateToLessonPlan() {
    history.push(`/app/lessonplan/view/${lastAccess.uuid}`);
  }

  return (
    <Card
      aria-label='Botão para navegar para a aula'
      className={classes.root}
      onClick={handleNavigateToLessonPlan}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.avatar}>
          <Typography variant='h3'>
            {getInitials(lastAccess.matter.title)}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.wrapper}>
        <Typography variant='h2' color='textPrimary' className={classes.title}>
          {lastAccess.matter.title}
        </Typography>
      </Box>
      <Box className={classes.wrapper}>
        <Box className={classes.label}>
          <Typography variant='h3'>{lastAccess.textYears}</Typography>
        </Box>
      </Box>
    </Card>
  );
}

LastAccessItem.propTypes = {
  lastAccess: PropTypes.object,
};

export default LastAccessItem;
