import React from 'react';
import {
  Grid,
  Container,
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core';

import Page from 'src/components/Page';

import Header from './Header';
import AccessMostChart from './AccessMostChart';
import BnccChart from './BnccChart';
import TeachersTable from './TeachersTable';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const HeadmasterView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title='Dashboard'>
      <Container maxWidth={false}>
        <Header />

        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Typography variant='h3'>Frequência de acessos</Typography>

            <Box m={2} />
            <AccessMostChart />
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography variant='h3'>BNCC aplicados</Typography>

            <Box m={2} />

            <BnccChart />
          </Grid>

          <Grid item md={12} xs={12}>
            <Typography variant='h3'>Aplicação de estratégias</Typography>

            <Box m={2} />

            <TeachersTable />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default HeadmasterView;
