import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes/routes';
import { THEMES } from 'src/constants';
import { LessonPlanProvider } from 'src/contexts/LessonPlanContext';
import { ParameterProvider } from 'src/contexts/ParameterContext';
import SnackbarCloseButton from './components/SnackbarCloseButton';
import { UserProvider } from './contexts/UserContext';
import { FirebaseAuthProvider } from './contexts/FirebaseAuthContext';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
  const theme = createTheme({
    direction: 'ltr',
    responsiveFontSizes: true,
    theme: THEMES.LIGHT,
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            preventDuplicate
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            action={key => <SnackbarCloseButton idClose={key} />}
          >
            <Router history={history}>
              <AuthProvider>
                <FirebaseAuthProvider>
                  <UserProvider>
                    <LessonPlanProvider>
                      <ParameterProvider>
                        <GlobalStyles />
                        <ScrollReset />
                        <GoogleAnalytics />
                        <CookiesNotification />
                        {/* <SettingsNotification /> */}
                        {renderRoutes(routes)}
                      </ParameterProvider>
                    </LessonPlanProvider>
                  </UserProvider>
                </FirebaseAuthProvider>
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
