import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Typography,
  makeStyles,
  IconButton,
  Grid,
  Button,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import EarlyYearsImg from 'src/assets/images/packages-illustrations/early-years.svg';
import FinalYearsImg from 'src/assets/images/packages-illustrations/final-years.svg';
import ChildEducationImg from 'src/assets/images/packages-illustrations/child-education.svg';
import { formatDate } from 'src/utils/formatDate';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    gap: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapperIcon: {
    width: 60,
    height: 60,
    borderRadius: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 35,
    height: 35,
    color: '#fffF',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  contentTitle: {
    fontSize: 24,
  },
  contentText: {
    fontSize: 20,
  },
  action: {
    borderRadius: 9,
    color: '#333',
    padding: theme.spacing(2),
  },
}));

const StudyPlans = () => {
  const classes = useStyles();

  const illustrationsToCategory = {
    early: EarlyYearsImg,
    final: FinalYearsImg,
    child: ChildEducationImg,
  };

  // const categoryName =
  //   packageObj.title === 'Educação Infantil'
  //     ? 'child'
  //     : packageObj.title === 'Anos Iniciais'
  //     ? 'early'
  //     : 'final';

  return (
    <Card className={classes.root}>
      <Grid item md={2}>
        <Box className={classes.wrapperIcon}>
          <img src={illustrationsToCategory['child']} width={60} height={60} />
        </Box>
      </Grid>
      <Grid item md={7} className={classes.content}>
        <Typography
          component='h4'
          gutterBottom
          variant='h4'
          color='textPrimary'
          className={classes.contentTitle}
        >
          Educação Infantil
        </Typography>
        <Grid item md={12}>
          <Typography
            variant='h5'
            color='textSecondary'
            className={classes.contentText}
          >
            1 / 5 disciplinas
          </Typography>
        </Grid>
      </Grid>
      <Grid container item md={3} justifyContent='flex-end'>
        <Button
          color='primary'
          variant='outlined'
          aria-label='more details'
          className={classes.action}
        >
          <Typography variant='button'>Acessar</Typography>
        </Button>
      </Grid>
    </Card>
  );
};

StudyPlans.propTypes = {
  event: PropTypes.object,
};

export default StudyPlans;
