import _ from 'lodash';
import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import useAuth from 'src/hooks/useAuth';
import api from 'src/services/api';

const initialAuthState = {
  user: null,
  users: [],
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'GET_STARTED_REQUEST': {
      return { ...state, loading: true };
    }
    case 'DONE_STARTED_REQUEST': {
      return { ...state, loading: false };
    }
    case 'GET_USER': {
      return { ...state, user: action.payload, loading: false };
    }
    case 'GET_USERS': {
      return { ...state, users: action.payload, loading: false };
    }
    case 'UPDATED_USER': {
      return { ...state, user: action.payload, loading: false };
    }
    default: {
      return { ...state };
    }
  }
};

export const UserContext = createContext({
  ...initialAuthState,
  getUserById: () => Promise.resolve(),
  getAllUser: (_limit, _page) => Promise.resolve(),
  createUser: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  deleteUser: () => Promise.resolve(),
  updateProfileImage: () => Promise.resolve(),
  deleteProfileImage: () => Promise.resolve(),
  updateChangePassword: () => Promise.resolve(),
  removeSubscribe: () => Promise.resolve(),
  getUserByType: () => Promise.resolve(),
});

const setSession = user => {
  localStorage.setItem('tooday:loggedUser', JSON.stringify(user));
};

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { user, isAuthenticated } = useAuth();

  const getAllUser = async (limit = 10, page = 1) => {
    try {
      const { data } = await api.get(`/users?length=${limit}&page=${page}`);

      if (data.length > 0) {
        const newUsers = _.unionBy([...state.users, ...data], 'uuid');

        dispatch({
          type: 'GET_USERS',
          payload: newUsers,
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const getUserById = async (uuid, userOwner = true) => {
    try {
      dispatch({ type: 'GET_STARTED_REQUEST' });

      const { data } = await api.get(`/users/${uuid}`);

      if (userOwner) {
        dispatch({
          type: 'GET_USER',
          payload: { ...data },
        });
      } else {
        dispatch({ type: 'DONE_STARTED_REQUEST' });
        return data;
      }
    } catch (err) {}
  };

  const getUserByType = async userType => {
    try {
      dispatch({ type: 'GET_STARTED_REQUEST' });

      const { data } = await api.get(`/users/types/${userType}`);

      return data;
    } catch (err) {}
  };

  const createUser = async values => {
    try {
      await api.post('/users', values);
    } catch (err) {
      if (err.response.data) {
        const { errors } = err.response.data;

        throw new Error(errors[0].message);
      }
      throw new Error(err);
    }
  };

  const updateUser = async values => {
    await api.put(`/users/${values.uuid}`, values);

    dispatch({
      type: 'UPDATED_USER',
      payload: values,
    });

    setSession(values);
  };

  const updateProfileImage = async (uuid, file) => {
    try {
      let formData = new FormData();
      formData.append('sendFile', true);
      formData.append('nameFile', file.name);
      formData.append('image', file);

      const { data } = await api.put(`/users/${uuid}/image`, formData);

      dispatch({
        type: 'UPDATED_USER',
        payload: data.userUpdated,
      });

      setSession(data);
    } catch (error) {
      throw new Error(error);
    }
  };

  const deleteProfileImage = async uuid => {
    try {
      const { data } = await api.delete(`/users/${uuid}/image`);

      dispatch({
        type: 'UPDATED_USER',
        payload: data.userUpdated,
      });
      setSession(data);
    } catch (error) {
      throw new Error(error);
    }
  };

  const updateChangePassword = async (uuid, values) => {
    try {
      await api.put(`/users/${uuid}/password/change`, values);
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data.error);
      }
    }
  };

  const deleteUser = async uuid => {
    try {
      await api.delete(`users/${uuid}`);
      // await getAllUser();
    } catch (err) {
      throw new Error(err);
    }
  };

  const removeSubscribe = async uuid => {
    await api.delete(`/subscribe`);

    await getUserById(uuid);
  };

  useEffect(() => {
    const unsubscribe = async () => {
      if (user) {
        await getUserById(user.uuid);
      }
    };

    unsubscribe();
  }, [user]);

  if (isAuthenticated && !state.user) {
    return <SplashScreen />;
  }

  return (
    <UserContext.Provider
      value={{
        ...state,
        getUserById,
        getAllUser,
        createUser,
        updateUser,
        deleteUser,
        updateProfileImage,
        deleteProfileImage,
        updateChangePassword,
        removeSubscribe,
        getUserByType,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
