import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {
  Card,
  Grid,
  IconButton,
  makeStyles,
  MobileStepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import api from 'src/services/api';

import LastAccessItem from './LastAccessItem';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    gap: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'Roboto',

    '& .MuiMobileStepper-dot': {
      width: 10,
      height: 10,
    },

    '& .MuiMobileStepper-dotActive': {
      background: theme.palette.secondary.main,
    },
  },
  content: {
    overflowX: 'auto',
  },

  pagination: {
    width: '100%',
    padding: 0,
    flexGrow: 1,
    background: theme.palette.grey[50],
    borderRadius: 9,
  },

  icon: {
    fontSize: theme.spacing(3),
  },
}));

const LastAccess = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const PER_PAGE = mobileDevice ? 1 : 4;

  const [currentPage, setCurrentPage] = useState(0);
  const [lastAccess, setLastAccess] = useState([]);

  const offset = currentPage * PER_PAGE;

  const pageCount = Math.ceil(lastAccess.length / PER_PAGE);

  function handleNext() {
    setCurrentPage(prevCurrentPage => prevCurrentPage + 1);
  }

  function handlePrevious() {
    setCurrentPage(prevCurrentPage => prevCurrentPage - 1);
  }

  async function fetch() {
    try {
      const { data: access } = await api.get('/last-access');

      setLastAccess(access);
    } catch (err) {}
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Card className={classes.root}>
      <Grid container wrap='nowrap' spacing={3} className={classes.content}>
        {lastAccess.length > 0 ? (
          <>
            {lastAccess.slice(offset, offset + PER_PAGE).map(access => (
              <Grid item md={3} sm={12} xs={12} key={access.uuid}>
                <LastAccessItem lastAccess={access.lessonPlan} />
              </Grid>
            ))}
          </>
        ) : (
          <Grid item md={12} sm={12} xs={12}>
            <Typography variant='h4' color='primary' align='center'>
              Você ainda não acessou nenhuma aula
            </Typography>
          </Grid>
        )}
      </Grid>

      {pageCount > 1 && (
        <MobileStepper
          variant='dots'
          steps={pageCount}
          position='static'
          activeStep={currentPage}
          className={classes.pagination}
          nextButton={
            <IconButton
              aria-label='next-page'
              color='secondary'
              onClick={handleNext}
              disabled={currentPage === pageCount - 1}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft fontSize='large' />
              ) : (
                <KeyboardArrowRight fontSize='large' />
              )}
            </IconButton>
          }
          backButton={
            <IconButton
              aria-label='previous-page'
              color='secondary'
              onClick={handlePrevious}
              disabled={currentPage === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight fontSize='large' />
              ) : (
                <KeyboardArrowLeft fontSize='large' />
              )}
            </IconButton>
          }
        />
      )}
    </Card>
  );
};

LastAccess.propTypes = {
  event: PropTypes.object,
};

export default LastAccess;
