import { userStatusEnum } from './index';

const BLOCKING_SOURCES = {
  [userStatusEnum.ACTIVE]: {
    text: 'Ativo',
    color: 'success',
  },
  [userStatusEnum.BLOCKED_BY_PASSWORD]: {
    text: 'Bloqueado por senha',
    color: 'error',
  },
  [userStatusEnum.BLOCKED_BY_USER]: {
    text: 'Bloqueado por usuário',
    color: 'error',
  },
  [userStatusEnum.INACTIVE]: {
    text: 'Usuário Inativo',
    color: 'error',
  },
  [userStatusEnum.BLOCKED_BY_MISCONDUCT]: {
    text: 'Bloqueado por Má Conduta',
    color: 'error',
  },
};

export default BLOCKING_SOURCES;
