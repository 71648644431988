import _ from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';
import { addMinutes, formatISO, parseISO } from 'date-fns';

import api from 'src/services/api';
import { formatDate } from 'src/utils/formatDate';

const initialState = {
  events: [],
  selectedEvent: null,
  selectedRange: null,
  isOpenModal: false,
  nextEvent: null,
  selectedDateAdd: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(state, action) {
      state.events = action.payload;
    },
    getNextEvent(state, action) {
      state.nextEvent = action.payload;
    },
    createEvent(state, action) {
      const event = action.payload;

      state.events = state.events.concat({
        id: event.uuid,
        matterId: event.matterId,
        title: event.title,
        date: JSON.parse(event.date),
        start: JSON.parse(event.start),
        end: JSON.parse(event.end),
      });
    },
    selectEvent(state, action) {
      const event = {
        ...action.payload,
        category: action.payload?.categories?.uuid || '',
        matter: action.payload?.matter?.uuid || '',
        plan: action.payload?.lessonPlans?.uuid || '',
      };

      const { categories, lessonPlans, ...newObj } = event;

      state.selectedEvent = newObj;
      state.isOpenModal = true;
    },
    updateEvent(state, action) {
      const event = action.payload;

      state.event = _.map(state.event, _event => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    deleteEvent(state, action) {
      const { eventId } = action.payload;

      state.events = _.filter(
        current(state.events),
        _event => _event.agendaId !== eventId
      );
    },
    selectRange(state, action) {
      const start = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start: new Date(),
        end: addMinutes(new Date(), 30),
        date: formatISO(start),
      };
    },
    openFormModal(state, action) {
      state.isOpenModal = true;
    },
    closeFormModal(state, action) {
      state.selectedDateAdd = null;
      state.selectedEvent = null;
      state.isOpenModal = false;
    },
    selectedDateAdd(state, action) {
      state.selectedDateAdd = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const getEvents = () => async dispatch => {
  const { data } = await api.get('/agenda');

  if (!data) return;

  const formattedEvents = data
    .map(event => {
      const [newDate] = event.date.split('T');

      return event.agendaData.map(agenda => ({
        id: event.uuid,
        matterId: agenda.matter?.uuid,
        agendaId: agenda.uuid,
        title: agenda.matter?.title,
        date: newDate,
        start: formatISO(new Date(`${newDate} ${agenda?.hourInitial}`)),
        end: formatISO(new Date(`${newDate} ${agenda?.hourFinal}`)),
      }));
    })
    .reduce((list, sub) => list.concat(sub), []);

  dispatch(slice.actions.getEvents(formattedEvents));
};

const getEventById = async uuid => {
  const { data } = await api.get(`/agenda/${uuid}`);

  if (!data) return;

  const [newDate] = data.date.split('T');

  const formattedEvent = data.agendaData
    .map(agenda => ({
      ...agenda,
      id: agenda.uuid,
      title: agenda.matter?.title,
      start: formatISO(new Date(`${newDate} ${agenda.hourInitial}`)),
      end: formatISO(new Date(`${newDate} ${agenda.hourFinal}`)),
      hourInitial: formatISO(new Date(`${newDate} ${agenda.hourInitial}`)),
      hourFinal: formatISO(new Date(`${newDate} ${agenda.hourFinal}`)),
    }))
    .reduce((list, sub) => list.concat(sub), []);

  return formattedEvent;
};

export const getNextEvent = () => async dispatch => {
  const { data: event } = await api.get('/agenda/events/next');

  if (!event) return;

  event.agendaData.forEach(agenda => {
    const dateParse = parseISO(`${event.date} ${agenda.hourInitial}`);

    agenda.hourInitial = formatISO(dateParse).toString();
  });

  dispatch(slice.actions.getNextEvent(event));
};

export const createEvent = async (body, dispatch) => {
  const formData = {
    data: formatDate(body.date, 'yyyy-MM-dd'),
    valor: {
      ...body,
      hourInitial: formatDate(body.start, 'HH:mm'),
      hourFinal: formatDate(body.end, 'HH:mm'),
    },
  };

  await api.post('/agenda', formData);

  dispatch(getEvents());
};

export const selectEvent = (eventId, agendaId) => async dispatch => {
  const events = await getEventById(eventId);

  const event = events.find(event => event.id === String(agendaId));

  dispatch(slice.actions.selectEvent(event));
};

export const updateEvent = (eventId, body) => async dispatch => {
  const formData = {
    ...body,
    hourInitial: formatDate(body.start, 'HH:mm'),
    hourFinal: formatDate(body.end, 'HH:mm'),
  };

  await api.put(`/agenda/${eventId}`, formData);

  dispatch(slice.actions.updateEvent({ id: eventId, ...body }));
};

export const deleteEvent = eventId => async dispatch => {
  await api.delete(`/agenda/${eventId}`);

  return dispatch(slice.actions.deleteEvent({ eventId }));
};

export const selectRange = start => dispatch => {
  dispatch(slice.actions.selectRange(start.getTime()));
};

export const handleOpenFormModal = () => dispatch => {
  return dispatch(slice.actions.openFormModal());
};

export const handleCloseFormModal = () => dispatch => {
  return dispatch(slice.actions.closeFormModal());
};

export const handleSelectedDateAdd = date => dispatch => {
  return dispatch(slice.actions.selectedDateAdd(date));
};

export default slice;
