import React, { useMemo, useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import Api from 'src/services/api';
import { Box, Card, makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
    },
  },
  video: {
    borderRadius: 9,
  },
}));

export default function AccessMostChart() {
  const classes = useStyles();
  const [topAccessUsers, setTopAccessUsers] = useState([]);

  const names = useMemo(() => topAccessUsers.map(data => data.name), [
    topAccessUsers,
  ]);

  const values = useMemo(
    () => topAccessUsers.map(data => data.numberOfAccesses),
    [topAccessUsers]
  );

  const theme = useTheme();

  const data = {
    series: [
      {
        data: values,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: names,
      },
    },
  };

  async function getTopAccessUsers() {
    try {
      const response = await Api.get(`/users/users-top-access`);

      setTopAccessUsers(response?.data || []);
    } catch (err) {
      console.error('Erro', err);
    }
  }

  useEffect(() => {
    getTopAccessUsers();
  }, []);

  return (
    <Card className={classes.root}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        minHeight={400}
      >
        <Chart
          options={data.options}
          series={data.series}
          type='bar'
          height={350}
          width={700}
        />
      </Box>
    </Card>
  );
}
