/* eslint-disable no-use-before-define */
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ForumIcon from '@material-ui/icons/Forum';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  matchPath,
  Link as RouterLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import Logo from 'src/components/Logo';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useParameter from 'src/hooks/useParameter';
import useUser from 'src/hooks/useUser';
import { userTypesEnum } from 'src/utils/enums';
import {
  adminSections,
  sections,
  sectionsHeadmasterUsers,
  sectionsInstitutionalUsers,
  sectionsInstitutionalUsersWithPermissionAdminApprove,
  sectionsInstitutionalUsersWithPermissionApprove,
} from './navigationConfig';
import NavItem from './NavItem';

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 48,
    height: 48,
  },

  boxProfile: {
    padding: '16px',
  },

  boxDataProfile: {
    alignItems: 'center',
    backgroundColor: 'rgb(244,245,247)',
    borderRadius: '16px',
    display: 'flex',
    overflow: 'hidden',
    padding: '16px',
  },

  boxDataProfileText: {
    marginLeft: '16px',
  },

  boxDataProfileTextBody2: {
    fontSize: '0.675rem',
    fontFamily: [
      '-apple system',
      'BlinkMacSystemFont',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Segoe UI"',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
    ].join(','),
    fontWeight: 400,
    lineHeight: '1.43',
    color: 'rgb(107,119,140)',
  },
  help: {
    display: 'flex',
    gap: 4,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useUser();
  const isMountedRef = useIsMountedRef();
  let history = useHistory();

  const { getParameterByName } = useParameter();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    if (location.pathname.includes('shop') && !!user.hasInstitution)
      history.push('/404');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const getParameter = useCallback(async () => {
    try {
      await getParameterByName('CONTACT_NUMBER');
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getParameter();
  }, [getParameter]);

  const getNavigation = user => {
    // console.log('## USEr', user);
    const { userType, hasInstitution, institution } = user;

    switch (userType) {
      case userTypesEnum.SUPER_ADMIN: {
        return sections;
      }

      case userTypesEnum.ADMIN: {
        return adminSections;
      }

      case userTypesEnum.DIRETOR: {
        return sectionsHeadmasterUsers;
      }

      default: {
        if (hasInstitution && institution[0].hasLec) {
          const { InstitutionUsers } = institution && institution[0];
          const { isAdmin, isManager } = InstitutionUsers;

          if (isManager) {
            return sectionsInstitutionalUsersWithPermissionApprove;
          }

          if (isAdmin) {
            return sectionsInstitutionalUsersWithPermissionAdminApprove;
          }
        }

        return sectionsInstitutionalUsers;
      }
    }
  };

  const content = (
    <Box height='100%' display='flex' flexDirection='column'>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display='flex' justifyContent='center'>
            <RouterLink to='/'>
              <Logo width={100} isDrawer />
            </RouterLink>
          </Box>
        </Hidden>
        <Box className={classes.boxProfile}>
          <Box className={classes.boxDataProfile}>
            <RouterLink to='/app/account'>
              <Avatar
                alt='User'
                className={classes.avatar}
                src={user.imageUrl}
              />
            </RouterLink>

            <Box className={classes.boxDataProfileText}>
              <Link
                component={RouterLink}
                to='/app/account'
                variant='h6'
                color='textPrimary'
                underline='none'
              >
                {user.name}
              </Link>

              <Typography
                className={classes.boxDataProfileTextBody2}
                color='textSecondary'
              >
                {user.email}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {getNavigation(user).map((section, index) => (
            <List
              key={section.subheader + index}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius='16px' bgcolor='background.dark'>
            <Link
              color='secondary'
              to='/app/contact'
              component={RouterLink}
              className={classes.help}
            >
              <Typography variant='h5' color='secondary'>
                Fale conosco
              </Typography>

              <ForumIcon />
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor='left'
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor='left'
          classes={{ paper: classes.desktopDrawer }}
          open
          variant='persistent'
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
