import clsx from 'clsx';
import React from 'react';

import { useStyles } from './styles';

export default function EarlyYears({ className }) {
  const classes = useStyles();

  return (
    <svg
      className={clsx(classes.root, className)}
      viewBox='0 0 148 168'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M45.18 70.0598L21.0211 83.3623C20.6762 83.5189 20.3038 83.606 19.9252 83.6185C19.5467 83.6311 19.1693 83.5688 18.8148 83.4353C18.4603 83.3018 18.1356 83.0998 17.8593 82.8406C17.583 82.5815 17.3605 82.2705 17.2046 81.9253L7.55978 60.5794C7.24458 59.8824 7.2191 59.0887 7.48896 58.373C7.75882 57.6572 8.30191 57.0779 8.99881 56.7625L34.6086 46.8849C35.5197 46.5335 36.5307 46.5442 37.4343 46.9146C38.3378 47.285 39.0653 47.9872 39.4676 48.877L46.8208 65.1435C47.2153 66.0192 47.2655 67.0113 46.9614 67.9223C46.6574 68.8334 46.0213 69.5965 45.18 70.0598V70.0598Z'
        stroke='#DADEEB'
        strokeWidth='1.37431'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M58.0383 120.626C53.2534 115.158 44.3487 103.552 39.4004 88.0638'
        stroke='#DADEEB'
        strokeWidth='1.37431'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M103.548 105.483C104.132 100.662 104.373 95.1716 103.553 90.2248'
        stroke='#DADEEB'
        strokeWidth='1.37431'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M109.066 106.835C109.066 106.835 108.325 99.3822 111.803 93.4943L117.843 96.4306C117.843 96.4306 111.183 99.6115 109.066 106.835Z'
        fill='#FFD5B8'
      />
      <path
        d='M55.0138 123.902C63.72 122.733 71.5642 128.235 72.6182 136.085C73.6721 143.935 67.5567 151.31 58.8504 152.479C50.1442 153.648 42.3 148.147 41.2461 140.297C40.1921 132.446 46.3075 125.071 55.0138 123.902Z'
        stroke='#DADEEB'
      />
      <path
        d='M106.737 73.6213C106.737 73.6213 109.992 73.1515 111.131 71.1288C111.715 70.0949 113.215 67.8565 115.379 66.3015C117.543 64.7466 115.825 69.5713 115.989 69.8994C116.204 70.3388 119.984 66.4138 120.844 67.2242C121.703 68.0345 122.946 70.4599 120.502 73.6623C118.058 76.8646 109.475 82.5311 106.806 82.7696C104.137 83.0081 103.927 76.757 106.737 73.6213Z'
        fill='#EF71C3'
      />
      <path
        d='M115.622 70.2589L114.196 72.3041'
        stroke='#0D0F44'
        strokeWidth='0.916209'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M35.8091 67.2595C35.8091 67.2595 34.8298 65.9732 34.1727 63.3702C33.5494 60.8905 31.9571 56.3175 30.4437 56.6322C29.7277 56.7811 28.3399 57.7297 29.3953 62.1785C29.3953 62.1785 29.4355 64.0635 25.6111 61.3348C21.7867 58.6061 20.6039 59.0776 19.8277 59.9819C18.4524 61.588 16.4611 65.3956 19.6813 69.657C22.9015 73.9184 31.2036 76.1589 31.2036 76.1589L35.8091 67.2595Z'
        fill='#EF71C3'
      />
      <path
        d='M20.0928 60.7896C20.0928 60.7896 23.0131 63.131 24.4927 63.5993'
        stroke='#0D0F44'
        strokeWidth='0.916209'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M19.4907 62.7793C19.4907 62.7793 22.409 65.1212 23.8907 65.5891'
        stroke='#0D0F44'
        strokeWidth='0.916209'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M19.0781 65.0872C19.0781 65.0872 21.9956 67.425 23.4781 67.8969'
        stroke='#0D0F44'
        strokeWidth='0.916209'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M19.6465 67.8185C20.5623 68.5461 21.5151 69.2258 22.5013 69.8549'
        stroke='#0D0F44'
        strokeWidth='0.916209'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M54.3588 132.865L72.8443 112.937C73.4141 112.322 74.1132 111.842 74.8912 111.531C75.6692 111.219 76.5066 111.085 77.343 111.136L112.97 113.302C113.585 113.339 114.18 113.536 114.694 113.875C115.209 114.213 115.625 114.68 115.902 115.23C116.179 115.78 116.307 116.393 116.273 117.008C116.239 117.623 116.044 118.218 115.708 118.734L93.9142 152.328C93.1901 153.448 92.0759 154.258 90.7878 154.603C89.4997 154.947 88.1295 154.801 86.9434 154.192L55.2243 137.833C54.7908 137.608 54.4151 137.287 54.1264 136.893C53.8378 136.499 53.644 136.044 53.5602 135.563C53.4764 135.082 53.5049 134.589 53.6434 134.121C53.782 133.652 54.0268 133.223 54.3588 132.865Z'
        fill='#0D0F44'
      />
      <path
        d='M115.259 112.282L116.322 116.655L114.549 118.938L115.259 112.282Z'
        fill='#0D0F44'
      />
      <path
        d='M52.9839 132.792L53.6714 136.099L57.3479 137.141L52.9839 132.792Z'
        fill='#0D0F44'
      />
      <path
        d='M53.7627 130.001L72.2477 110.071C72.8176 109.456 73.5167 108.976 74.2947 108.665C75.0727 108.353 75.9101 108.219 76.7464 108.27L112.374 110.436C112.989 110.473 113.583 110.67 114.098 111.009C114.612 111.347 115.029 111.814 115.306 112.364C115.583 112.914 115.711 113.527 115.677 114.142C115.643 114.757 115.448 115.352 115.111 115.868L93.3177 149.462C92.592 150.581 91.4767 151.389 90.1883 151.732C88.8998 152.074 87.53 151.926 86.3448 151.315L54.6278 134.967C54.1947 134.742 53.8193 134.421 53.5309 134.027C53.2425 133.633 53.0489 133.179 52.9652 132.698C52.8815 132.217 52.9099 131.724 53.0482 131.256C53.1866 130.788 53.4311 130.358 53.7627 130.001Z'
        fill='#48AB96'
      />
      <path
        d='M109.86 117.583L102.024 129.655L101.406 128.336C100.434 126.197 97.3737 119.401 94.4473 112.233L107.557 113.025C108.073 113.055 108.573 113.22 109.005 113.503C109.438 113.787 109.788 114.179 110.021 114.641C110.254 115.102 110.362 115.617 110.334 116.133C110.306 116.65 110.142 117.149 109.86 117.583Z'
        fill='#DADEEB'
      />
      <path
        d='M74.2383 140.879L75.0956 141.848L59.1152 133.61C58.7517 133.421 58.4367 133.151 58.1948 132.821C57.9529 132.491 57.7906 132.109 57.7205 131.706C57.6505 131.303 57.6746 130.889 57.791 130.496C57.9075 130.104 58.113 129.744 58.3917 129.444L63.8415 123.57C66.6505 129.709 70.1378 135.516 74.2383 140.879Z'
        fill='#DADEEB'
      />
      <path
        d='M66.8432 31.5412C66.8432 31.5412 72.8381 29.2042 76.4007 40.436C79.1344 49.055 80.2564 50.2515 80.685 51.0191C81.1136 51.7866 87.9614 56.885 87.7267 63.7374C87.7267 63.7374 86.9836 72.5828 79.5765 75.5818C72.1694 78.5808 67.3564 59.9921 67.3564 59.9921C67.3564 59.9921 61.2555 35.9063 66.8432 31.5412Z'
        fill='#48AB96'
      />
      <path
        d='M40.1178 118.67C43.8686 117.89 46.277 114.217 45.497 110.466C44.7171 106.715 41.0442 104.307 37.2934 105.087C33.5426 105.867 31.1342 109.54 31.9141 113.291C32.6941 117.041 36.367 119.45 40.1178 118.67Z'
        fill='#EF71C2'
      />
      <path
        d='M41.0864 111.249L39.1713 115.165C39.1395 115.232 39.0899 115.289 39.0278 115.33C38.9658 115.37 38.8937 115.393 38.8196 115.396C38.7454 115.399 38.6719 115.381 38.6071 115.344C38.5424 115.308 38.4888 115.255 38.4524 115.19L36.0176 110.974C35.98 110.91 35.9607 110.837 35.9617 110.763C35.9628 110.689 35.9842 110.616 36.0237 110.554C36.0631 110.491 36.1191 110.44 36.1854 110.407C36.2518 110.373 36.326 110.359 36.4 110.365L40.7462 110.657C40.814 110.661 40.88 110.681 40.938 110.716C40.996 110.752 41.0441 110.801 41.078 110.86C41.1119 110.919 41.1304 110.985 41.1319 111.053C41.1333 111.121 41.1177 111.188 41.0864 111.249Z'
        fill='white'
      />
      <path
        d='M126.086 48.1316L109.004 57.672C108.72 57.8305 108.407 57.9315 108.084 57.9693C107.761 58.0071 107.434 57.9809 107.121 57.8923C106.809 57.8036 106.516 57.6542 106.261 57.4525C106.006 57.2509 105.793 57.001 105.635 56.7171L99.5623 45.8437C99.2547 45.293 99.1688 44.6456 99.3223 44.0337C99.4758 43.4219 99.8571 42.8917 100.388 42.5515L117.017 31.8929C117.346 31.7089 117.708 31.5916 118.083 31.5477C118.457 31.5038 118.837 31.5342 119.2 31.6371C119.563 31.74 119.902 31.9133 120.198 32.1473C120.493 32.3813 120.74 32.6713 120.924 33.0007L127.189 44.223C127.561 44.8877 127.653 45.6728 127.446 46.4057C127.24 47.1386 126.75 47.7594 126.086 48.1316Z'
        fill='#48AB96'
      />
      <path
        d='M116.769 45.6011L114.458 47.1516L110.723 45.3209L110.535 42.5459C110.526 42.4054 110.554 42.265 110.616 42.1386C110.678 42.0121 110.771 41.904 110.888 41.8248L116.212 38.2512C116.256 38.2215 116.308 38.2039 116.361 38.2005C116.415 38.197 116.469 38.2076 116.517 38.2314C116.565 38.2551 116.606 38.2911 116.636 38.3358C116.666 38.3804 116.683 38.4321 116.687 38.4856L117.121 44.8824C117.129 45.0224 117.102 45.1621 117.04 45.2881C116.978 45.4141 116.885 45.5219 116.769 45.6011V45.6011Z'
        stroke='white'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M114.476 47.1601L112.949 50.2733C112.793 50.5892 112.341 50.6852 111.937 50.4836L109.646 49.3584C109.243 49.1608 109.042 48.7432 109.196 48.4277L110.723 45.3209L114.476 47.1601Z'
        stroke='white'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M116.518 38.2311L114.309 42.7357'
        stroke='white'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M114.049 44.8695C114.663 44.742 115.057 44.1413 114.929 43.5278C114.801 42.9144 114.201 42.5205 113.587 42.648C112.974 42.7756 112.58 43.3763 112.708 43.9897C112.835 44.6032 113.436 44.9971 114.049 44.8695Z'
        stroke='white'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M102.029 129.654L91.5741 145.771C90.9653 146.709 90.0296 147.388 88.9487 147.675C87.8677 147.962 86.7187 147.837 85.7246 147.325L75.0961 141.847L74.2389 140.879C70.1383 135.515 66.651 129.709 63.8421 123.57C58.1326 111.218 56.5582 101.178 56.5582 101.178L88.3961 94.6034C89.0662 98.4329 91.6975 105.505 94.4518 112.232C97.3783 119.4 100.439 126.196 101.411 128.334L102.029 129.654Z'
        fill='#0D0F44'
      />
      <path
        d='M71.8848 113L74.9639 113.497C74.9639 113.497 76.9303 122.526 92.2686 143.539'
        stroke='#DADEEB'
        strokeWidth='0.916209'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M63.1319 56.3985C63.1319 56.3985 54.7036 58.656 52.8156 65.4984C50.4893 73.8452 45.6236 73.901 37.4776 67.4752C37.1766 67.2369 36.8013 67.1123 36.4176 67.1232C36.0339 67.1341 35.6663 67.2799 35.3794 67.5348C33.2482 69.4265 28.9473 73.9689 32.1032 77.4035C36.1946 81.8542 47.5827 90.5669 55.2754 88.3342C55.2754 88.3342 55.7134 97.476 56.5736 101.175C56.5736 101.175 62.6472 106.252 74.571 103.5C86.4948 100.747 88.4115 94.6002 88.4115 94.6002C88.4115 94.6002 86.6514 85.4787 86.5553 83.0363C86.5553 83.0363 95.663 87.2902 106.822 82.7702C106.822 82.7702 104.164 77.7504 106.752 73.6179C106.752 73.6179 94.6359 72.8369 93.007 71.3423C91.3781 69.8477 85.8597 60.7834 84.8651 59.4632C83.5637 57.7328 80.2274 55.0723 74.7764 54.5918C69.3254 54.1112 63.1319 56.3985 63.1319 56.3985Z'
        fill='#48AB96'
      />
      <path
        d='M54.9343 66.3325C54.9343 66.3325 53.8741 74.4492 55.1175 86.9765'
        stroke='#DADEEB'
        strokeWidth='0.916209'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M74.5869 67.1355C74.5869 67.1355 78.4607 74.3047 80.9974 78.411C81.8228 79.7471 82.5066 80.7589 82.8694 81.094C84.3472 82.4586 86.5373 83.0399 86.5373 83.0399'
        stroke='white'
        strokeWidth='0.916209'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M104.489 73.4492C104.489 73.4492 101.455 75.9219 104.391 82.6902'
        stroke='white'
        strokeWidth='0.916209'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M38.5948 68.3376C38.5948 68.3376 34.7767 71.3415 33.5276 75.6735'
        stroke='white'
        strokeWidth='0.916209'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M56.564 35.8694C56.564 35.8694 53.2015 38.3274 57.0745 43.7774L59.1528 37.3031L56.564 35.8694Z'
        fill='#48AB96'
      />
      <path
        d='M70.9787 45.1655C70.9787 45.1655 71.0854 55.2122 71.2484 55.9957C71.4113 56.7791 66.4521 59.1015 63.0177 57.2933C63.0177 57.2933 63.8876 50.5301 63.9441 48.8713C64.0006 47.2124 68.0064 44.5544 70.9787 45.1655Z'
        fill='#EF71C3'
      />
      <path
        d='M70.9787 45.1655L63.7969 50.6711C63.7003 51.6099 63.5734 52.731 63.4424 53.7928C63.4424 53.7928 70.7132 53.4418 70.9787 45.1655Z'
        fill='#0D0F44'
      />
      <path
        d='M71.2493 41.1839C72.1076 45.3115 69.8748 50.4357 65.9733 51.247C61.2248 52.2344 58.0293 49.9626 57.121 44.1217C56.4788 39.9781 58.7314 35.994 62.6329 35.1828C66.5344 34.3715 70.3915 37.0582 71.2493 41.1839Z'
        fill='#EF71C3'
      />
      <path
        d='M68.0033 42.0223C63.8849 43.3028 57.6512 41.2076 56.5639 35.8694C55.4767 30.5313 60.3247 29.3163 61.0646 29.1625C61.8044 29.0086 68.4715 28.6404 71.7967 34.6905C75.122 40.7407 69.7292 41.4854 68.0033 42.0223Z'
        fill='#48AB96'
      />
      <path
        d='M72.4062 44.2929C73.7185 43.1218 74.202 41.5221 73.486 40.7199C72.7701 39.9176 71.1259 40.2167 69.8136 41.3878C68.5013 42.5589 68.0179 44.1586 68.7338 44.9609C69.4498 45.7631 71.094 45.4641 72.4062 44.2929Z'
        fill='#EF71C3'
      />
      <path
        d='M65.2689 48.4157C65.2689 48.4157 66.9406 48.2853 67.3085 46.721'
        stroke='#0D0F44'
        strokeWidth='0.916209'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M70.581 43.2928L72.5994 41.8737'
        stroke='#0D0F44'
        strokeWidth='0.916209'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M61.1509 44.1859L61.099 46.2349C61.0975 46.2944 61.1084 46.3535 61.1308 46.4085C61.1533 46.4636 61.1869 46.5134 61.2295 46.5549C61.2721 46.5963 61.3228 46.6285 61.3785 46.6495C61.4342 46.6704 61.4935 46.6796 61.5529 46.6765L62.0387 46.6541'
        stroke='#0D0F44'
        strokeWidth='0.916209'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M59.6759 43.5439C59.6261 42.9643 59.3436 42.5151 59.0448 42.5408C58.7461 42.5664 58.5442 43.0571 58.594 43.6368C58.6437 44.2165 58.9263 44.6656 59.225 44.64C59.5238 44.6143 59.7256 44.1236 59.6759 43.5439Z'
        fill='#0D0F44'
      />
      <path
        d='M63.9049 43.3968C63.8551 42.8171 63.5726 42.368 63.2738 42.3936C62.9751 42.4193 62.7732 42.91 62.823 43.4897C62.8727 44.0694 63.1553 44.5185 63.454 44.4928C63.7528 44.4672 63.9547 43.9765 63.9049 43.3968Z'
        fill='#0D0F44'
      />
      <path
        d='M32.8971 24.4753C32.323 23.1479 31.4114 21.9939 30.2529 21.1282C29.0944 20.2626 27.7294 19.7153 26.2937 19.541C24.8581 19.3667 23.4018 19.5714 22.0698 20.1347C20.7378 20.698 19.5765 21.6002 18.7015 22.7517C17.8265 23.9031 17.2682 25.2637 17.0823 26.6979C16.8964 28.1321 17.0893 29.59 17.6418 30.9265C18.1943 32.263 19.0871 33.4315 20.2314 34.3158C21.3758 35.2001 22.7318 35.7694 24.1644 35.9669C24.4882 36.0138 24.7944 36.1435 25.0535 36.3434C25.3125 36.5432 25.5156 36.8065 25.6432 37.1078L26.035 37.9969C26.2122 38.4056 26.4681 38.7754 26.7882 39.0851C27.1083 39.3949 27.4864 39.6386 27.9007 39.8022C28.315 39.9659 28.7575 40.0463 29.2029 40.0389C29.6482 40.0315 30.0878 39.9365 30.4965 39.7592C30.9052 39.582 31.275 39.326 31.5848 39.0059C31.8945 38.6858 32.1382 38.3078 32.3018 37.8935C32.4655 37.4792 32.5459 37.0367 32.5385 36.5913C32.5312 36.1459 32.4361 35.7063 32.2588 35.2977L31.8686 34.4166C31.7375 34.1192 31.6846 33.7931 31.7151 33.4695C31.7456 33.1458 31.8585 32.8354 32.0429 32.5677C32.871 31.407 33.3848 30.0518 33.5345 28.6339C33.6842 27.2159 33.4646 25.7833 32.8971 24.4753Z'
        fill='#FFD5B8'
      />
      <path
        d='M27.1784 35.8449L31.7151 33.8793'
        stroke='#48AB96'
        strokeWidth='2.29052'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M24.8096 21.1575C24.8096 21.1575 28.2684 20.9639 29.8512 23.7097'
        stroke='white'
        strokeWidth='1.83242'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M15.1863 24.4436C15.1863 24.4436 13.121 28.0452 15.5598 32.3201'
        stroke='#48AB96'
        strokeWidth='2.29052'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M12.1742 22.9055C12.1742 22.9055 9.13807 28.2071 12.7234 34.5032'
        stroke='#48AB96'
        strokeWidth='2.29052'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  );
}
