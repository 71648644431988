import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    path: '/login/callback',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    path: '/forgotpassword',
    component: lazy(() => import('src/views/auth/ForgotPassword')),
  },
  {
    exact: true,
    path: '/newpassword',
    component: lazy(() => import('src/views/auth/NewPassword')),
  },

  {
    exact: true,
    guard: GuestGuard,
    path: '/terms',
    component: lazy(() => import('src/components/TermsView')),
  },

  {
    exact: true,
    path: '/callback',
    component: lazy(() => import('src/components/LoginWithSocialCallback')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      /**
       * Lesson Plan
       */
      {
        exact: true,
        path: '/app',
        component: HomeView,
      },
      {
        exact: true,
        path: '/app/lessonplan',
        component: lazy(() =>
          import('src/views/lessonPlan/LessonPlanListViewAdmin')
        ),
      },
      {
        exact: true,
        path: '/app/lessonplan/categories/:uuid?',
        component: lazy(() =>
          import('src/views/lessonPlan/LessonPlanListView')
        ),
      },
      {
        exact: true,
        path: '/app/lessonplan/create',
        component: lazy(() =>
          import('src/views/lessonPlan/LessonPlanCreateView')
        ),
      },
      {
        exact: true,
        path: '/app/lessonplan/view/:uuid',
        component: lazy(() => import('src/views/lessonPlan/LessonPlanView')),
      },
      {
        exact: true,
        path: '/app/lessonplan/:uuid/edit',
        component: lazy(() =>
          import('src/views/lessonPlan/LessonPlanCreateView')
        ),
      },
      {
        exact: true,
        path: '/app/parameters',
        component: lazy(() =>
          import('src/views/parameters/ParametersListView')
        ),
      },
      {
        exact: true,
        path: '/app/parameters/create',
        component: lazy(() =>
          import('src/views/parameters/ParametersCreateView')
        ),
      },
      {
        exact: true,
        path: '/app/parameters/:uuid/edit',
        component: lazy(() =>
          import('src/views/parameters/ParametersEditView')
        ),
      },
      {
        exact: true,
        path: '/app/mail-marketing',
        component: lazy(() =>
          import('src/views/mailMarketing/MailMarketingList')
        ),
      },
      {
        exact: true,
        path: '/app/mail-marketing/create',
        component: lazy(() =>
          import('src/views/mailMarketing/MailMarketingCreate')
        ),
      },
      {
        exact: true,
        path: '/app/mail-marketing/edit/:id',
        component: lazy(() =>
          import('src/views/mailMarketing/MailMarketingEdit')
        ),
      },
      {
        exact: true,
        path: '/app/users/create',
        component: lazy(() => import('src/views/users/UserRegisterView')),
      },
      {
        exact: true,
        path: '/app/users/view',
        component: lazy(() => import('src/views/users/UserListView')),
      },
      {
        exact: true,
        path: '/app/users/edit/:id',
        component: lazy(() => import('src/views/users/UserEditView')),
      },

      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView')),
      },
      {
        exact: true,
        path: '/app/calendar/:lessonPlanId?',
        component: lazy(() => import('src/views/calendar/CalendarView')),
      },

      // {
      //   exact: true,
      //   path: '/app/payment/:plan/',
      //   component: lazy(() => import('src/views/payment'))
      // },

      // {
      //   exact: true,
      //   path: '/app/shop/:plan?',
      //   component: lazy(() => import('src/views/shop'))
      // },

      {
        exact: true,
        path: '/app/institution',
        component: lazy(() =>
          import('src/views/institution/InstitutionTableView')
        ),
      },
      {
        exact: true,
        path: '/app/institution/contract-view',
        component: lazy(() => import('src/components/InstitutionContractView')),
      },
      {
        exact: true,
        path: '/app/institution/create',
        component: lazy(() =>
          import('src/views/institution/InstitutionCreateView')
        ),
      },
      {
        exact: true,
        path: '/app/institution/edit/:id',
        component: lazy(() =>
          import('src/views/institution/InstitutionEditView')
        ),
      },

      {
        exact: true,
        path: '/app/publishing',
        component: lazy(() =>
          import('src/views/publishing/PublishingTableView')
        ),
      },
      {
        exact: true,
        path: '/app/publishingUser/:uuid?',
        component: lazy(() =>
          import('src/views/publishing/PublishingListViewTeacher')
        ),
      },

      {
        exact: true,
        path: '/app/publishing/create',
        component: lazy(() =>
          import('src/views/publishing/PublishingCreateView')
        ),
      },

      {
        exact: true,
        path: '/app/publishing/edit/:id',
        component: lazy(() =>
          import('src/views/publishing/PublishingEditView')
        ),
      },

      {
        exact: true,
        path: '/app/school/view',
        component: lazy(() => import('src/views/approve-school/table-view')),
      },

      {
        exact: true,
        path: '/app/school/:schoolId/class',
        component: lazy(() => import('src/views/approve-class/table-view')),
      },

      {
        exact: true,
        path: '/app/class/:classId/students',
        component: lazy(() => import('src/views/approve-student/table-view')),
      },

      {
        exact: true,
        path: '/app/class',
        component: lazy(() => import('src/views/class/ClassTableView')),
      },
      {
        exact: true,
        path: '/app/class/view/:id',
        component: lazy(() => import('src/views/class/ClassView')),
      },

      {
        exact: true,
        path: '/app/institution/create',
        component: lazy(() => import('src/views/class/ClassCreateView')),
      },
      {
        exact: true,
        path: '/app/classes',
        component: lazy(() => import('src/views/headmaster/UserClassListView')),
      },
      {
        exact: true,
        path: '/app/classes-created/:uuid',
        component: lazy(() =>
          import('src/views/headmaster/ClassesByUserTableView')
        ),
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to='/app/chat/new' />,
      },
      {
        exact: true,
        path: '/app/contact',
        component: lazy(() => import('src/views/contact')),
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?',
        ],
        component: lazy(() => import('src/views/mail/MailView')),
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to='/app/mail/all' />,
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/project/OverviewView')),
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/project/ProjectBrowseView')),
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/project/ProjectCreateView')),
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/project/ProjectDetailsView')),
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to='/app/projects/browse' />,
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView')),
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() =>
          import('src/views/reports/DashboardAlternativeView')
        ),
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to='/app/reports/dashboard' />,
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to='/app/reports/dashboard' />,
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView')),
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView')),
      },
      {
        exact: true,
        path: '/app/faq',
        component: lazy(() => import('src/views/faq/FaqsTableView')),
      },
      {
        exact: true,
        path: '/app/faq/create',
        component: lazy(() => import('src/views/faq/FaqCreateView')),
      },
      {
        exact: true,
        path: '/app/faq/edit/:id',
        component: lazy(() => import('src/views/faq/FaqsEditView')),
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to='/app/social/profile' />,
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: () => <Redirect to='/app/reports/dashboard' />,
      },
      {
        exact: true,
        path: '/app/assessments',
        component: lazy(() => import('src/views/assessments')),
      },
      {
        exact: true,
        path: '/app/assessments/edit/:uuid',
        component: lazy(() =>
          import('src/views/assessments/AssessmentsEditView')
        ),
      },
      {
        exact: true,
        path: '/app/assessments/:uuid/create-answer',
        component: lazy(() =>
          import('src/views/assessments/AssessmentsCreateView')
        ),
      },
      {
        exact: true,
        path: '/app/assessments/details/:uuid',
        component: lazy(() =>
          import('src/views/assessments/AssessmentsDetailsView')
        ),
      },
      {
        exact: true,
        path: '/app/newAssessments',
        component: lazy(() => import('src/views/newAssessments')),
      },
      {
        exact: true,
        path: '/app/newAssessments/create',
        component: lazy(() =>
          import('src/views/newAssessments/NewAssessmentsCreateView')
        ),
      },
      {
        exact: true,
        path: '/app/newAssessments/edit/:uuid',
        component: lazy(() =>
          import('src/views/newAssessments/NewAssessmentsEditView')
        ),
      },
      {
        exact: true,
        path: '/app/newAssessments/details/:uuid',
        component: lazy(() =>
          import('src/views/newAssessments/NewAssessmentsDetailsView')
        ),
      },
      {
        component: () => <Redirect to='/404' />,
      },
    ],
  },
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to='/docs/welcome' />,
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView')),
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView')),
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() =>
          import('src/views/docs/EnvironmentVariablesView')
        ),
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView')),
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/APICallsView')),
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView')),
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView')),
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView')),
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView')),
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView')),
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView')),
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView')),
      },
      {
        component: () => <Redirect to='/404' />,
      },
    ],
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to='/app' />,
      },
      {
        component: () => <Redirect to='/404' />,
      },
    ],
  },
];

export default routes;
