import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import EventIcon from '@material-ui/icons/Event';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router';
import { isAfter, min, isEqual } from 'date-fns';
import {
  Box,
  Card,
  Typography,
  makeStyles,
  IconButton,
  Grid,
} from '@material-ui/core';

import { formatDate } from 'src/utils/formatDate';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    gap: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      gap: theme.spacing(2),
    },
  },
  wrapperIcon: {
    width: 60,
    height: 60,
    borderRadius: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down('xs')]: {
      width: 50,
      height: 50,
    },
  },
  icon: {
    width: 35,
    height: 35,
    color: '#fffF',

    [theme.breakpoints.down('xs')]: {
      width: 30,
      height: 30,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    // background: 'green'
  },
  contentTitle: {
    fontSize: '1.5rem',

    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
  },
  contentText: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  action: {
    width: 60,
    height: 60,
    borderRadius: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[100],
    transition: 'background 0.3s',

    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },

    [theme.breakpoints.down('xs')]: {
      width: 50,
      height: 50,
    },
  },
  actionIcon: {
    width: 35,
    height: 35,
    color: '#333',
  },
}));

const NextEventItem = ({ event }) => {
  const classes = useStyles();
  const history = useHistory();

  const [nextTime, setNextTime] = useState();
  const [nextEvent, setNextEvent] = useState();

  const now = new Date();

  function handleNavigateNextEvent() {
    const nextEventDate = event.date ?? new Date();

    const query = new URLSearchParams();

    query.set('date', nextEventDate);

    history.push(`/app/calendar?${query}`);
  }

  useEffect(() => {
    if (event.agendaData) {
      const eventTimesAfter = min(
        event.agendaData
          .filter(agd => isAfter(new Date(agd.hourInitial), now))
          .map(agenda => new Date(agenda.hourInitial))
      );

      const nextEventFound = event.agendaData.find(agenda =>
        isEqual(new Date(eventTimesAfter), new Date(agenda.hourInitial))
      );

      setNextTime(eventTimesAfter);
      setNextEvent(nextEventFound);
    }
  }, [event]);

  return (
    <Card className={classes.root}>
      <Grid item>
        <Box className={classes.wrapperIcon}>
          <EventIcon className={classes.icon} />
        </Box>
      </Grid>
      <Grid item md={7} lg={8} sm={7} xs={7} className={classes.content}>
        <Typography
          component='h4'
          gutterBottom
          variant='h4'
          color='textPrimary'
          className={classes.contentTitle}
        >
          Sua próxima aula
        </Typography>
        <Grid item md={12} xs={12}>
          {nextEvent ? (
            <Typography
              variant='h5'
              color='textSecondary'
              className={classes.contentText}
            >
              {formatDate(nextTime, 'PPPp')} • {` ${nextEvent.school}, `}{' '}
              {`Mat. ${nextEvent.matter?.matter}`}
            </Typography>
          ) : (
            <Typography variant='h5' color='textSecondary'>
              Você ainda não possui aula
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container item md={3} xs={2} justifyContent='flex-end'>
        <IconButton
          color='primary'
          aria-label='more details'
          className={classes.action}
          onClick={handleNavigateNextEvent}
        >
          <ChevronRightIcon className={classes.actionIcon} />
        </IconButton>
      </Grid>
    </Card>
  );
};

NextEventItem.propTypes = {
  event: PropTypes.shape({
    uuid: PropTypes.string,
    date: PropTypes.string,
    agendaData: PropTypes.arrayOf(
      PropTypes.shape({
        address: PropTypes.string,
        hourFinal: PropTypes.date,
        hourInitial: PropTypes.string,
        matter: PropTypes.shape({
          matter: PropTypes.string,
          title: PropTypes.string,
        }),
        plan: PropTypes.string,
        school: PropTypes.string,
        schoolClass: PropTypes.string,
        shiftSchedule: PropTypes.string,
        subject: PropTypes.string,
      })
    ),
    annotation: PropTypes.arrayOf(),
  }),
};

export default NextEventItem;
