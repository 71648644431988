import { initializeApp, getApps, getApp } from 'firebase/app';
import 'firebase/auth';

import { firebaseConfig } from 'src/config';

let firebaseClient;

if (getApps().length) {
  firebaseClient = getApp();
} else {
  firebaseClient = initializeApp(firebaseConfig);
}

export default firebaseClient;
