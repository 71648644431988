import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';

export const formatDate = (date, pattern) => {
  const formattedDate = format(new Date(date), pattern, {
    locale: ptBR,
  });

  return formattedDate;
};
