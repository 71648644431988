import jwtDecode from 'jwt-decode';
import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import api from 'src/services/api';

const initialAuthState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
  alreadyHasUserLogged: false,
};

const isValidToken = accessToken => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const setSession = (accessToken, currentUser) => {
  if (accessToken) {
    localStorage.setItem('tooday:accessToken', accessToken);
    localStorage.setItem('tooday:loggedUser', JSON.stringify(currentUser));
    api.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  }
};

const removeSession = () => {
  localStorage.removeItem('tooday:accessToken');
  localStorage.removeItem('tooday:loggedUser');
  delete api.defaults.headers['Authorization'];
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user, isAuthenticated, alreadyHasUserLogged } = action.payload;

      return {
        ...state,
        user,
        isAuthenticated,
        alreadyHasUserLogged,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        alreadyHasUserLogged: false,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  logoutForced: (email = '') => Promise.resolve(),
  dispatch: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (emailOrCpf, password) => {
    const { data } = await api.post('/signing', { emailOrCpf, password });

    if (data.logged === false) {
      const { token } = data;

      const user = jwtDecode(token);
      setSession(token, user);

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          isAuthenticated: true,
          alreadyHasUserLogged: true,
        },
      });
    } else {
      throw new Error('Usuário já está logado!');
    }
  };

  const logout = async () => {
    try {
      await api.post('logout');

      removeSession();
      dispatch({ type: 'LOGOUT' });
    } catch (err) {}
  };

  const logoutForced = async email => {
    await api.post('signing/logoutForced', { email });
  };

  const register = async ({ email, name, password, cpf }) => {
    await api.post('/sign-up/create', {
      email,
      name,
      password,
      cpf,
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = localStorage.getItem('tooday:accessToken');
        const userlogged = JSON.parse(
          localStorage.getItem('tooday:loggedUser')
        );

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, userlogged);

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: userlogged,
            },
          });
        } else {
          removeSession();

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        removeSession();

        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        dispatch,
        register,
        logoutForced,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
