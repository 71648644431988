export const userTypesEnum = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  COMERCIAL: 3,
  PEDAGOGO: 4,
  EDITOR: 5,
  DIRETOR: 6,
  COORDENADOR: 7,
  PROFESSOR: 8,
  GESTOR_ESCOLAR: 9,
};

export const plansEnum = {
  SILVER: 1,
  GOLD: 2,
  PLATINUM: 3,
  FREE: 4,
  CONTINUE_FORMATION: 5,
  INSTITUTION: 6,
};

export const institutionTypes = [
  { label: 'Escola Pública', code: 1 },
  { label: 'Escola Privada', code: 2 },
  { label: 'Universidade Publica', code: 3 },
  { label: 'Universidade Privada', code: 4 },
  { label: 'Prefeitura', code: 5 },
];

export const institutionTypesEnum = {
  PUBLIC_SCHOOL: 1,
  PRIVATE_SCHOOL: 2,
  PUBLIC_UNIVERSITY: 3,
  PRIVATE_UNIVERSITY: 4,
  TOWN_HALL: 5,
};

export const contactTypes = [
  { label: 'Celular', value: 1 },
  { label: 'Telefone', value: 2 },
  { label: 'Email', value: 3 },
];

export const contactTypesEnum = {
  CELL_PHONE: 1,
  PHONE: 2,
  EMAIL: 3,
};

export const userPlanTypes = plansEnum;
