import {
  Avatar,
  Box,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Edit as EditIcon,
  Search as SearchIcon,
  Trash2 as TrashIcon,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Label from 'src/components/Label';
import SplashScreen from 'src/components/SplashScreen';
import { userTypes } from 'src/enums';
import BLOCKING_SOURCES from 'src/enums/blockingSources';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import api from 'src/services/api';
import { formatDate } from 'src/utils/formatDate';
import { getInitialNameCharacters } from 'src/utils/utilityFunctions';

const useStyles = makeStyles(theme => ({
  root: {},
  queryField: {
    width: 500,
    marginBottom: theme.spacing(2),
  },
  bulkOperations: {
    position: 'relative',
  },
  textCodeStyle: {
    fontWeight: 'bold',
  },
  label: {
    textTransform: 'capitalize',
    borderRadius: 8,
    padding: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '& > span': {
      marginRight: theme.spacing(1.5),
      fontSize: theme.spacing(1.5),

      '&[aria-label="true"]': {
        color: 'green',
      },
      '&[aria-label="false"]': {
        color: 'red',
      },
    },
  },
  headText: {
    fontSize: theme.spacing(2.2),
  },
  bodyText: {
    fontSize: theme.spacing(1.8),
  },
  bodyAvatar: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  bodyTextVendor: {
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const tableHeaders = [
  {
    id: '1',
    name: 'Nome',
    field: 'name',
    align: 'left',
  },

  {
    id: '2',
    name: 'Estratégias aplicadas',
    field: 'strategiesApplied',
    align: 'left',
  },
  {
    id: '3',
    name: 'Disponíveis',
    field: 'strategiesAvailable',
    align: 'left',
  },
  {
    id: '4',
    name: 'Percentual de estratégias',
    field: 'strategiesAppliedPercentage',
    align: 'left',
  },
  {
    id: '5',
    name: 'Status',
    field: 'status',
    align: 'left',
  },
];

function TeachersTable({ className }) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();

  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [query, setQuery] = useState('');
  const [sortBy, setSortBy] = useState('updatedAt');
  const [sort, setSort] = useState('desc');
  const [users, setUsers] = useState([]);
  // const [value, setValue] = useState('');

  function handleChangePage(_event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  // function handleChange(event) {
  //   setValue(event.target.value);
  //   debouncedChangeHandler(event.target.value);
  // }

  // const debouncedChangeHandler = useCallback(
  //   debounce(value => setQuery(value), 1000),
  //   []
  // );

  const getAllUsers = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/strategie-applied/users-top-applied?length=${rowsPerPage}&page=${page +
          1}`
      );

      if (isMountedRef.current) {
        setUsers(data.results);
        setTotalUsers(data.total);
      }
    } catch (err) {
      console.error('Erro', err);
    } finally {
      setLoading(false);
    }
  }, [isMountedRef, page, rowsPerPage]);

  function handleRequestSort(property) {
    const isAsc = sortBy === property && sort === 'ASC';
    setSort(isAsc ? 'DESC' : 'ASC');
    setSortBy(property);
  }

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  if (loading) return <SplashScreen />;

  return (
    <Card className={clsx(classes.root, className)}>
      <Box
        p={2}
        minHeight={56}
        minWidth='100%'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography color='textSecondary' gutterBottom variant='body2'>
          {totalUsers} registros encontrados. Página {page + 1} de{' '}
          {Math.ceil(totalUsers / rowsPerPage)}
        </Typography>
      </Box>

      <Box flexGrow={1} />

      <PerfectScrollbar>
        <Box minWidth={700}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaders.map(head =>
                    head.field ? (
                      <TableCell
                        key={head.id}
                        align={head.align}
                        className={classes.headText}
                        sortDirection={sortBy === head.field ? sort : false}
                      >
                        <TableSortLabel
                          active={sortBy === head.field}
                          direction={
                            sortBy === head.field ? sort.toLowerCase() : 'asc'
                          }
                          onClick={() => handleRequestSort(head.field)}
                        >
                          {head.name}
                        </TableSortLabel>
                      </TableCell>
                    ) : (
                      <TableCell
                        key={head.id}
                        align={head.align}
                        className={classes.headText}
                      >
                        {head.name}
                      </TableCell>
                    )
                  )}
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {users.map(user => (
                  <TableRow hover key={user.uuid}>
                    <TableCell>
                      <Box
                        pl={2}
                        display='flex'
                        alignItems='flex-start'
                        justifyContent='center'
                      >
                        <Avatar
                          alt={user.name}
                          src={user.imageUrl}
                          className={classes.bodyAvatar}
                          onClick={() => handleEditUser(user.uuid)}
                        >
                          {getInitialNameCharacters(user.name)}
                        </Avatar>

                        <Box
                          minWidth='100%'
                          display='flex'
                          flexDirection='column'
                          alignItems='flex-start'
                          justifyContent='center'
                          gridGap={2}
                          pl={2}
                          className={classes.bodyText}
                        >
                          <Link
                            color='inherit'
                            component={RouterLink}
                            variant='h6'
                            to={`/app/users/edit/${user.uuid}`}
                          >
                            {user.name}
                          </Link>

                          <span>{user.email}</span>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell className={classes.bodyText}>
                      {user.strategiesApplied || '-----'}
                    </TableCell>

                    <TableCell className={classes.bodyText}>
                      {user.strategiesAvailable || '-----'}
                    </TableCell>

                    <TableCell className={classes.bodyText}>
                      {user?.strategiesAppliedPercentage || '-----'}
                    </TableCell>

                    <TableCell className={classes.bodyText}>
                      <Label
                        color={BLOCKING_SOURCES[user.status].color}
                        text={BLOCKING_SOURCES[user.status].text}
                      >
                        {BLOCKING_SOURCES[user.status].text}
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component='div'
            page={page}
            count={totalUsers}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10]}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}

TeachersTable.propTypes = {
  className: PropTypes.string,
};

export default TeachersTable;
