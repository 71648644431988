import React, { createContext, useEffect, useReducer } from 'react';
import _ from 'lodash';

import useUser from 'src/hooks/useUser';
import api from 'src/services/api';
import { plansEnum, userTypesEnum } from 'src/utils/enums';

const initialState = {
  lessonPlan: null,
  lessonPlans: [],
  error: null,
  status: null,
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE_LESSON': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'GET_LESSONPLANS_SUCCESS': {
      const { lessonPlans } = action.payload;

      return {
        ...state,
        lessonPlans,
        status: 200,
        loading: false,
      };
    }

    case 'UPDATED_LESSONPLAN_SUCCESS':
    case 'GET_LESSONPLAN_SUCCESS': {
      const { lessonPlan } = action.payload;
      return {
        ...state,
        lessonPlan,
        loading: false,
      };
    }

    case 'DELETED_LESSONPLAN_SUCCESS':
    case 'CREATE_LESSONPLAN_SUCCESS': {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const LessonPlanContext = createContext({
  ...initialState,
  getAllLessons: () => Promise.resolve(),
  getLessonPlanById: () => Promise.resolve(),
  createLessonPlan: () => Promise.resolve(),
  updateLessonPlan: () => Promise.resolve(),
  associateBnccToLessonPlan: () => Promise.resolve(),
  unAssociateBnccLessonPlan: () => Promise.resolve(),
  associateStrategy: () => Promise.resolve(),
  unassociateStrategy: () => Promise.resolve(),
  associateRef: () => Promise.resolve(),
  unassociateRef: () => Promise.resolve(),
  associateFiles: () => Promise.resolve(),
  unAssociateFiles: () => Promise.resolve(),
  deleteLessonPlan: () => Promise.resolve(),
  getFileLessonPlan: () => Promise.resolve(),
  editStrategy: () => Promise.resolve(),
  changeStatusLessonPlan: () => Promise.resolve(),
});

export const LessonPlanProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { user } = useUser();

  // const getLessonsByPlanType = async classPlans => {
  //   const { data: categories } = await api.get('/categories');

  //   const rules = {
  //     [plansEnum.SILVER]: () => {
  //       let matters;

  //       if (user.id_categories_selected) {
  //         const categorySelected = JSON.parse(user.id_categories_selected);

  //         matters = categories
  //           .find(c => c.uuid === categorySelected[0])
  //           ?.matters.map(matter => matter.uuid);
  //       } else {
  //         matters = JSON.parse(user.id_matters_selected);
  //       }

  //       const lessons = classPlans.filter(customer =>
  //         matters.includes(customer.matter.uuid)
  //       );

  //       return lessons;
  //     },
  //     [plansEnum.GOLD]: () => {
  //       let matters = [];

  //       if (user.id_categories_selected) {
  //         const categorySelected = JSON.parse(user.id_categories_selected);

  //         matters = categories
  //           .filter(c => categorySelected.includes(c.uuid))
  //           .map(category => category.matters.map(matter => matter.uuid))
  //           .reduce((list, sub) => list.concat(sub), []);
  //       } else if (user.id_matters_selected) {
  //         matters.push(JSON.parse(user.id_matters_selected));
  //       }

  //       const lessons = classPlans.filter(customer =>
  //         matters.includes(customer.matter.uuid)
  //       );

  //       return lessons;
  //     },
  //     [plansEnum.FREE]: () => {
  //       const matters = categories
  //         .map(category => category.matters.map(matter => matter.uuid))
  //         .reduce((list, sub) => list.concat(sub), []);

  //       const filter = classPlans.filter(customer =>
  //         matters.includes(customer.matter.uuid)
  //       );

  //       const getOneLessonByMatter = _.uniqBy(filter, function(e) {
  //         return e.matter.uuid;
  //       });

  //       return getOneLessonByMatter;
  //     }
  //   };

  //   return rules[user.id_plantype]();
  // };

  const getAllLessons = async () => {
    dispatch({ type: 'INITIALIZE_LESSON' });

    const { data } = await api.get('/lessonplan');

    let lessonPlans = data;

    dispatch({
      type: 'GET_LESSONPLANS_SUCCESS',
      payload: {
        lessonPlans,
      },
    });
  };

  const getLessonPlanById = async lessonPlanUUID => {
    dispatch({ type: 'INITIALIZE_LESSON' });
    const response = await api.get(`/lessonplan/` + lessonPlanUUID);

    dispatch({
      type: 'GET_LESSONPLAN_SUCCESS',
      payload: {
        lessonPlan: response.data,
      },
    });
  };

  const createLessonPlan = async obj => {
    dispatch({ type: 'INITIALIZE_LESSON' });
    const response = await api.post(`/lessonplan`, obj);
    dispatch({
      type: 'CREATE_LESSONPLAN_SUCCESS',
      payload: {
        lessonPlan: response.data,
      },
    });
  };

  const editStrategy = async obj => {
    const response = await api.put(`/strategy/${obj.uuidStrategy}`, obj);
  };

  const updateLessonPlan = async obj => {
    dispatch({ type: 'INITIALIZE_LESSON' });
    const response = await api.put(`/lessonplan/${obj.uuid}`, obj);
    dispatch({
      type: 'UPDATED_LESSONPLAN_SUCCESS',
      payload: {
        lessonPlan: response.data,
      },
    });
  };

  const changeStatusLessonPlan = async uuid => {
    const response = await api.put(`/lessonplan/${uuid}/changestatus`);
  };

  const associateFiles = async (uuid, filesArr, lessonPlanName) => {
    dispatch({ type: 'INITIALIZE_LESSON' });

    let formData = new FormData();
    if (filesArr) {
      filesArr.forEach(file => {
        if (file && file.size) {
          formData.append('lessonPlanFileName', file.name);
          formData.append('lessonPlanFiles', file);
        }
      });
    }

    await api.post(`/lessonplan/associate/${uuid}/files`, formData);
    dispatch({
      type: 'ASSOCIATE_FILES_LESSONPLAN_SUCCESS',
    });

    getLessonPlanById(uuid);
  };

  const unAssociateFiles = async (uuid, values) => {
    dispatch({ type: 'INITIALIZE_LESSON' });
    await api.post(`/lessonplan/unassociate/${uuid}/files`, values);
    dispatch({
      type: 'UNASSOCIATE_FILES_LESSONPLAN_SUCCESS',
    });

    getLessonPlanById(uuid);
  };

  const associateRef = async (uuid, values) => {
    dispatch({ type: 'INITIALIZE_LESSON' });

    await api.post(`/lessonplan/associate/${uuid}/ref`, values);
    dispatch({
      type: 'ASSOCIATE_REF_LESSONPLAN_SUCCESS',
    });

    getLessonPlanById(uuid);
  };

  const unassociateRef = async (uuid, values) => {
    dispatch({ type: 'INITIALIZE_LESSON' });

    await api.post(`/lessonplan/unassociate/${uuid}/ref`, values);
    dispatch({
      type: 'UNASSOCIATE_REF_LESSONPLAN_SUCCESS',
    });

    getLessonPlanById(uuid);
  };

  const associateStrategy = async (uuid, values) => {
    dispatch({ type: 'INITIALIZE_LESSON' });

    await api.post(`/lessonplan/associate/${uuid}/strategy`, values);
    dispatch({
      type: 'ASSOCIATE_STRATEGY_LESSONPLAN_SUCCESS',
    });

    getLessonPlanById(uuid);
  };

  const unassociateStrategy = async (uuid, values) => {
    dispatch({ type: 'INITIALIZE_LESSON' });

    await api.post(`/lessonplan/unassociate/${uuid}/strategy`, values);
    dispatch({
      type: 'UNASSOCIATE_STRATEGY_LESSONPLAN_SUCCESS',
    });

    getLessonPlanById(uuid);
  };

  const associateBnccToLessonPlan = async (uuid, listBncc) => {
    dispatch({ type: 'INITIALIZE_LESSON' });

    await api.post(`/lessonplan/associate/${uuid}/bncc`, listBncc);
    dispatch({
      type: 'ASSOCIATE_BNCC_LESSONPLAN_SUCCESS',
    });

    getLessonPlanById(uuid);
  };

  const unAssociateBnccLessonPlan = async (uuid, listBncc) => {
    dispatch({ type: 'INITIALIZE_LESSON' });

    await api.post(`/lessonplan/unassociate/${uuid}/bncc`, listBncc);
    dispatch({
      type: 'UNASSOCIATE_BNCC_LESSONPLAN_SUCCESS',
    });

    getLessonPlanById(uuid);
  };

  const deleteLessonPlan = async lessonPlanUUID => {
    dispatch({ type: 'INITIALIZE_LESSON' });

    await api
      .delete(`/lessonplan/${lessonPlanUUID}`)
      .then(() => getAllLessons());
    dispatch({
      type: 'DELETED_LESSONPLAN_SUCCESS',
    });
  };

  const getFileLessonPlan = async ({ idFile, uuidLessonPlan }) => {
    await api
      .get(
        `/lessonplan/getFileLessonPlan?idFile=${idFile}&uuidLessonPlan=${uuidLessonPlan}`
      )
      .then(result => {
        window.open(result.data.link, '_blank');
      });
  };

  return (
    <LessonPlanContext.Provider
      value={{
        ...state,
        getAllLessons,
        getLessonPlanById,
        createLessonPlan,
        updateLessonPlan,
        associateBnccToLessonPlan,
        unAssociateBnccLessonPlan,
        associateStrategy,
        unassociateStrategy,
        associateRef,
        unassociateRef,
        associateFiles,
        unAssociateFiles,
        deleteLessonPlan,
        getFileLessonPlan,
        editStrategy,
        changeStatusLessonPlan,
      }}
    >
      {children}
    </LessonPlanContext.Provider>
  );
};

export default LessonPlanContext;
