import axios from 'axios';
import { getToken } from './getToken';

let token;

const serverUrl = process.env.REACT_APP_API_URL;

const Api = axios.create({ baseURL: serverUrl });

Api.interceptors.request.use(async config => {
  token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

Api.interceptors.response.use(
  res => res,
  error => {
    if (
      (error && error.response && error.response.status === 401) ||
      (typeof error.response.data === 'string' &&
        error.response.data.includes('jwt expired'))
    ) {
      localStorage.removeItem('tooday:accessToken');
      localStorage.removeItem('tooday:loggedUser');
      delete axios.defaults.headers.common['Authorization'];

      if (window.location.pathname !== '/login') {
        window.location.href = '/auth/login/';
      }
    }

    return Promise.reject(error);
  }
);

export default Api;
