import React, { useEffect, useState, useMemo } from 'react';
import Chart from 'react-apexcharts';
import Api from 'src/services/api';

import { Box, Card, makeStyles, useTheme } from '@material-ui/core';
import useParameter from 'src/hooks/useParameter';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
    },
  },
  video: {
    borderRadius: 9,
  },
}));

export default function BnccChart() {
  const classes = useStyles();
  const theme = useTheme();

  const [bnccApplied, setBnccApplied] = useState([]);

  const codes = useMemo(() => bnccApplied.map(data => data.bnccCode), [
    bnccApplied,
  ]);

  const values = useMemo(() => bnccApplied.map(data => data.useCounter), [
    bnccApplied,
  ]);

  const data = {
    series: values,
    options: {
      chart: {
        width: '100%',
        type: 'pie',
      },
      labels: codes,
      responsive: [
        {
          breakpoint: 200,
          options: {
            chart: {
              width: 500,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  async function getBnccApplied() {
    try {
      const response = await Api.get(`/strategie-applied/bnccs-applied`);

      setBnccApplied(response?.data || []);
    } catch (err) {
      console.error('Erro', err);
    }
  }

  useEffect(() => {
    getBnccApplied();
  }, []);

  return (
    <Card className={classes.root}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        minHeight={400}
      >
        <Chart
          options={data.options}
          series={data.series}
          type='pie'
          width={'450'}
        />
      </Box>
    </Card>
  );
}
