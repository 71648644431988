import { capitalize } from 'lodash';
import qs from 'qs';

export const generatePasswordDefault = username => {
  return `${capitalize(username.split(' ')[0])}@${new Date().getFullYear()}`;
};

export const isValidCPF = cpf => {
  if (typeof cpf !== 'string') return false;
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  cpf = cpf.split('');
  const validator = cpf
    .filter((digit, index, array) => index >= array.length - 2 && digit)
    .map(el => +el);
  const toValidate = pop =>
    cpf
      .filter((digit, index, array) => index < array.length - pop && digit)
      .map(el => +el);
  const rest = (count, pop) =>
    ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
      10) %
      11) %
    10;
  return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
};

export const getInitials = value => {
  let newValue = String(value);

  if (String(newValue).indexOf(',') < 0) {
    let str = newValue.split(' ');

    if (str.length > 1) {
      newValue = `${str[0].substring(0, 1)}${str[1].substring(0, 1)}`;
      return newValue;
    } else if (newValue.toLocaleLowerCase().includes('matemática')) {
      newValue = newValue.substring(0, 3);
    } else newValue = newValue.substring(0, 2);
  } else {
    newValue = newValue.substring(0, 2);
  }

  return newValue.toUpperCase();
};

export const generateCouponCode = () => {
  const first = (Math.random() + 1).toString(36).substring(7);
  const last = (Math.random() + 1).toString(36).substring(7);

  return `${first.toUpperCase()}${last.toUpperCase()}`;
};

export const formatCurrency = value => {
  return Number(value).toLocaleString('pt-BR', {
    currency: 'BRL',
    style: 'currency',
  });
};

export const formatDocumentType = document => {
  const CPF_VALUE_LENGTH = 11;
  const CNPJ_VALUE_LENGTH = 14;

  let newValue = String(document).replace(/\D/g, '');

  if (newValue.length === CPF_VALUE_LENGTH) {
    newValue = document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else if (newValue.length === CNPJ_VALUE_LENGTH) {
    newValue = document.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  }

  return newValue;
};

export const convertStringToNumber = value => {
  const removeCharacters = String(value).replace(/\D/g, '');

  return Number(removeCharacters);
};

/**
 * Remove object keys that are empty (null | undefined | '') and returns the object with
 * properties that are NOT empty.
 * @param {object} obj A javascript object.
 */
const removeEmpty = obj => {
  const auxObj = JSON.parse(JSON.stringify(obj));
  Object.entries(auxObj).forEach(([key, val]) => {
    if (val && typeof val === 'object') removeEmpty(val);
    else if (val === null || val === undefined || val === '')
      delete auxObj[key];
  });
  return auxObj;
};

/**
 *
 * @param {*} searchObj - Object containing attributes for a filter search
 * @return {string} String with the expected parameters in api rest
 */
export const queryBuilder = searchObj => {
  const cleanSearch = removeEmpty(searchObj);
  const query = qs.stringify(cleanSearch, { arrayFormat: 'brackets' });
  return query ? `&${query}` : '';
};

export const getInitialNameCharacters = (name = '') => {
  const characters = name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase())
    .join('');

  return characters;
};

export const ROWS_PER_PAGE = [5, 10, 15, 20];

export const QUESTION_OPTIONS_SCORES = ['100', '75', '50', '25', '0'];

export const QUESTION_OPTIONS_CRITICAL_LEVEL = [
  'Avançado',
  'Intermediário',
  'Elementar',
];

export const ASSESSMENT_OPTIONS_SIMULATE = [
  {
    id: 1,
    value: '1',
    text: '1',
  },
  {
    id: 2,
    value: '2',
    text: '2',
  },
  {
    id: 3,
    value: '3',
    text: '3',
  },
  {
    id: 4,
    value: '4',
    text: '4',
  },
];

export const ASSESSMENT_SUBJECTS = [
  {
    id: 1,
    value: 'PORTUGUES',
    text: 'Português',
  },
  {
    id: 2,
    value: 'MATEMATICA',
    text: 'Matemática',
  },
];
export const ASSESSMENT_YEARS = [
  {
    id: 1,
    value: '1',
    text: '1° Ano',
  },
  {
    id: 2,
    value: '2',
    text: '2° Ano',
  },
  {
    id: 3,
    value: '3',
    text: '3° Ano',
  },
  {
    id: 4,
    value: '4',
    text: '4° Ano',
  },
  {
    id: 5,
    value: '5',
    text: '5° Ano',
  },
  {
    id: 6,
    value: '6',
    text: '6° Ano',
  },
  {
    id: 7,
    value: '7',
    text: '7° Ano',
  },
  {
    id: 8,
    value: '8',
    text: '8° Ano',
  },
  {
    id: 9,
    value: '9',
    text: '9° Ano',
  },
];
