import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: 200,
    height: 200,

    [theme.breakpoints.down('xs')]: {
      width: 120,
      height: 120,
    },
  },
}));

export { useStyles };
