import React, { useEffect } from 'react';
import {
  Grid,
  Container,
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core';

import Page from 'src/components/Page';
import { getNextEvent } from 'src/slices/calendar';
import { useDispatch, useSelector } from 'src/store';

import Header from './Header';
import NextEventItem from './NextEventItem';
import StudyPlans from './StudyPlans';
import LastAccess from './LastAccess';
import Recommended from './Recommended';
import PromotionalVideo from './PromotionalVideo';
import useUser from 'src/hooks/useUser';
import { userPlanTypes } from 'src/utils/enums';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100,
  },
}));

const HomeView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { nextEvent } = useSelector(state => state.calendar);
  const { user } = useUser();

  useEffect(() => {
    dispatch(getNextEvent());
  }, []);

  return (
    <Page className={classes.root} title='Pagina inicial'>
      <Container maxWidth={false}>
        <Header />
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xs={12}>
            <NextEventItem event={nextEvent || {}} />
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography variant='h3'>Últimos acessos</Typography>

            <Box m={2} />

            <LastAccess event={nextEvent} />
          </Grid>

          <Grid item md={6} xs={12}>
            <PromotionalVideo />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default HomeView;
