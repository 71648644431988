import React, { createContext, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import {
  getAuth,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  isSignInWithEmailLink,
  signInWithPopup,
} from '@firebase/auth';

import 'src/lib/firebase';
import api from 'src/services/api';
import firebase from 'src/lib/firebase';
import useAuth from 'src/hooks/useAuth';
import useRouter from 'src/utils/useRouter';
import { getProvider } from 'src/lib/firebase-providers';

import { setSession } from './JWTAuthContext';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const FirebaseAuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithSocialLogin: () => Promise.resolve(),
  signInWithEmail: () => Promise.resolve(),
  isSignInWithEmailLinkSuccess: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const FirebaseAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const { dispatch: dispatcher } = useAuth();

  const auth = getAuth();
  const router = useRouter();

  const signInWithEmailAndPassword = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const handleCreateUser = userInfo => {
    router.history.push('/register', userInfo);
  };

  const signInWithSocialLogin = async (providerId = 'google') => {
    let userLogged;
    let provider = getProvider(providerId);
    provider.addScope('email');

    const { user, _tokenResponse: tokenResponse } = await signInWithPopup(
      auth,
      provider
    );

    userLogged = {
      email: user?.email ?? tokenResponse.email,
      name: user.displayName,
    };

    try {
      const { data } = await api.post('signing/social-networks', {
        email: user?.email ?? tokenResponse.email,
        accessToken: user.accessToken,
      });

      if (data.logged === false) {
        const { token } = data;

        const user = jwtDecode(token);
        setSession(token, user);

        dispatcher({
          type: 'LOGIN',
          payload: {
            user,
            isAuthenticated: true,
          },
        });
      } else {
        throw new Error(`Usuário já está logado email:${userLogged.email}`);
      }
    } catch (err) {
      if (err.response.status === 404) {
        handleCreateUser(userLogged);
      }
    }
  };

  const signInWithEmail = async email => {
    const actionCodeSettings = {
      url: `${window.location.href}/callback`,
      handleCodeInApp: true,
      // iOS: {
      //   bundleId: 'com.example.ios'
      // },
      // android: {
      //   packageName: 'com.example.android',
      //   installApp: true,
      //   minimumVersion: '12'
      // },
      // dynamicLinkDomain: 'https://usetooday.page.link/ZCg5'
    };

    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        localStorage.setItem('emailForSignIn', email);
      })
      .catch(console.log);
  };

  const isSignInWithEmailLinkSuccess = async () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email = localStorage.getItem('emailForSignIn');

      if (email) {
        try {
          const {
            user,
            _tokenResponse: tokenResponse,
          } = await signInWithEmailLink(auth, email, window.location.href);

          const { data } = await api.post('signing/social-networks', {
            email: user?.email ?? tokenResponse?.email,
            accessToken: user.accessToken,
          });

          if (data.logged === false) {
            const { token } = data;

            const user = jwtDecode(token);
            setSession(token, user);

            dispatcher({
              type: 'LOGIN',
              payload: {
                user,
                isAuthenticated: true,
                alreadyHasUserLogged: true,
              },
            });

            localStorage.removeItem('emailForSignIn');
          } else {
            throw new Error('Usuário já está logado!');
          }
        } catch (err) {
          console.log(err);
          localStorage.removeItem('emailForSignIn');
          router.history.push('/');
        }
      } else {
        router.history.push('/');
      }
    } else {
      router.history.push('/');
    }
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const logout = () => {
    return firebase.auth().signOut();
  };

  return (
    <FirebaseAuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithEmail,
        isSignInWithEmailLinkSuccess,
        signInWithSocialLogin,
        logout,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  );
};
