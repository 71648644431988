import { GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';

const providersAvailable = {
  google: GoogleAuthProvider.PROVIDER_ID,
  facebook: FacebookAuthProvider.PROVIDER_ID,
};

const supportedPopupSignInMethods = new Set([
  providersAvailable.google,
  providersAvailable.facebook,
]);

export function getProvider(providerName) {
  const providerId = providersAvailable[providerName];

  if (supportedPopupSignInMethods.has(providerId)) {
    switch (providerId) {
      case GoogleAuthProvider.PROVIDER_ID:
        return new GoogleAuthProvider();
      case FacebookAuthProvider.PROVIDER_ID:
        return new FacebookAuthProvider();
      default:
        throw new Error(`No provider implemented for ${providerId}`);
    }
  } else {
    throw new Error(`No provider implemented for ${providerId}`);
  }
}
