import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';

import useRouter from 'src/utils/useRouter';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Header = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justifyContent='space-between'
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize='small' />}
          aria-label='breadcrumb'
        >
          <Link
            variant='body1'
            color='inherit'
            to='/app'
            component={RouterLink}
          >
            Página inicial
          </Link>
        </Breadcrumbs>
        {/* <Box mt={1} mb={2}>
          <Typography variant="h3" color="secondary">
            Pronto para mais um dia produtivo?
          </Typography>
        </Box> */}
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
