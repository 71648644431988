import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';
import useParameterContext from 'src/hooks/useParameter';
import useUser from 'src/hooks/useUser';
import { plansEnum } from 'src/utils/enums';
import ChildEducationIllustration from './illustrations/ChildEducation';
import EarlyYearsIllustration from './illustrations/EarlyYears';
import FinalYearsIllustration from './illustrations/FinalYears';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    borderRadius: 16,
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(3),
  },
  description: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#D7DADD',
  },
  ButtonKnowMoreText: {
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 0,
    cursor: 'pointer',
  },
  knowMoreText: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '16px',
    color: '#D7DADD',
    textDecorationLine: 'underline',
  },
  oldPrice: {
    textDecoration: 'line-through',
  },
  button: {
    maxHeight: 50,
    color: '#fff',
  },
  wrapperIllustration: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  lockIcon: {
    top: theme.spacing(2),
    right: theme.spacing(2),
    alignSelf: 'end',
    justifySelf: 'start',
  },
  wrapper: {
    gap: theme.spacing(2),
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
    width: '50%',
  },
  buttonText: {
    textAlign: 'center',
  },
}));

function CardShop({
  className,
  data,
  handleOpenMoreDetails,
  openCancelSignatureDialog,
}) {
  const classes = useStyles();
  const history = useHistory();

  const { user } = useUser();
  const { variables } = useParameterContext();

  const phone = variables['CONTACT_NUMBER']?.value || '';
  const phoneLinkWhatsapp = phone.replace(/[^A-Z0-9]/gi, '');

  function formatCurrency(value) {
    return Number(value).toLocaleString('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    });
  }

  function getIllustration(type) {
    const illustrations = {
      child: ChildEducationIllustration,
      early: EarlyYearsIllustration,
      final: FinalYearsIllustration,
    };

    return illustrations[type];
  }

  function handleNavigate(path) {
    if (data.id === plansEnum.FREE) return;

    history.push(path);
  }

  function handleClickButton() {
    if (data.id === user.id_plantype) {
      openCancelSignatureDialog();
      return;
    }

    handleNavigate(data.navigatePath);
  }

  function generateButton({ ...rest }) {
    return (
      <Button
        fullWidth
        color='primary'
        variant='contained'
        className={classes.button}
        disabled={data.id === plansEnum.FREE}
        {...rest}
      >
        <Typography variant='button' className={classes.buttonText}>
          {data.id === user.id_plantype
            ? 'Cancelar Assinatura'
            : data.buttonText}
        </Typography>
      </Button>
    );
  }

  return (
    <Grid item className={clsx(classes.root, className)}>
      <Container className={classes.wrapper}>
        <Typography variant='h3'>{data.title}</Typography>
        <Typography variant='subtitle2' className={classes.description}>
          {data.shortDescription}
        </Typography>
        <Button
          variant='text'
          className={classes.ButtonKnowMoreText}
          onClick={() => handleOpenMoreDetails(data)}
        >
          <Typography variant='body1' className={classes.knowMoreText}>
            Saiba mais
          </Typography>
        </Button>
        {data.price && (
          <Box align='end'>
            <Typography variant='h2'>{formatCurrency(data.price)}</Typography>
            {/* <Typography variant="h4" className={classes.oldPrice}>
              {formatCurrency(data.oldPrice)}
            </Typography> */}
          </Box>
        )}
      </Container>
      <Container className={classes.wrapper}>
        <Box className={classes.lockIcon}>
          {data.id === user.id_plantype ? (
            <LockOpenIcon size='small' />
          ) : (
            <LockIcon size='small' />
          )}
        </Box>
        <Box className={classes.wrapperIllustration}>
          {getIllustration(data.type)}
        </Box>
        {[plansEnum.CONTINUE_FORMATION, plansEnum.INSTITUTION].includes(data.id)
          ? generateButton({
              target: '_blank',
              href: `https://api.whatsapp.com/send?phone=55${phoneLinkWhatsapp}&text=Ol%C3%A1!`,
            })
          : generateButton({ onClick: handleClickButton })}
      </Container>
    </Grid>
  );
}

CardShop.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    price: PropTypes.number,
    type: PropTypes.oneOf(['early', 'child', 'final']),
    shortDescription: PropTypes.string,
    fullDescription: PropTypes.string,
    navigatePath: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  handleOpenMoreDetails: PropTypes.func,
  openCancelSignatureDialog: PropTypes.func,
};

export default CardShop;
