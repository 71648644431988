import { Card, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import CardShop from 'src/components/CardShop';
import MoreDetailsModal from 'src/components/CardShop/MoreDetailsModal';
import { plansEnum } from 'src/utils/enums';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
    },
  },
  item: {
    height: 325,
  },
}));

const Recommended = () => {
  const classes = useStyles();
  const [isOpenMoreDetails, setIsOpenMoreDetails] = useState(false);

  const planData = {
    id: plansEnum.PLATINUM,
    title: 'Plano Ouro',
    price: 38.7,
    oldPrice: 99.9,
    type: 'final',
    navigatePath: '/app/shop',
    buttonText: 'Eu quero',
    shortDescription: `Nosso pacote Ouro, com essa assinatura você tem acesso todas as categorias.`,
    fullDescription: `
      <p>Mais de 3000 estratégias de aulas criadas pela curadoria Tooday, baseados
      nas competências e habilidades da BNCC, organizadas por campos de
      experiência e/ou componentes curriculares, com detalhamento de todo o
      material e referências necessárias para o desenvolvimento da sua aula,
      além de atividades exclusivas. Incluindo, adaptações curriculares para
      alunos com necessidades especiais em todos os temas abordados.<p>
      <br />
      <h4>Aqui você tem acesso aos segmentos: </h4>
      <br />
      <ul style="padding-left: 24px;">
        <li>Educação Infantil</li>
        <li>Ensino Fundamental: Anos iniciais</li>
        <li>Ensino Fundamental: Anos Finais (todos os componentes curriculares).</li>
      </ul> 
    `,
  };

  function handleOpenMoreDetails() {
    setIsOpenMoreDetails(true);
  }

  function handleClose() {
    setIsOpenMoreDetails(false);
  }

  return (
    <Card className={classes.root}>
      <CardShop
        className={classes.item}
        data={planData}
        handleOpenMoreDetails={handleOpenMoreDetails}
      />

      <MoreDetailsModal
        planSelected={planData}
        isOpen={isOpenMoreDetails}
        handleClose={handleClose}
      />
    </Card>
  );
};

export default Recommended;
