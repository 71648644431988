import React from 'react';
import { IconButton } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

const SnackbarCloseButton = ({ idClose, ...props }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      style={{ color: '#fff' }}
      onClick={() => closeSnackbar(idClose)}
    >
      <IconClose />
    </IconButton>
  );
};

export default SnackbarCloseButton;
