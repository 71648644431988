import React from 'react';
import useUser from 'src/hooks/useUser';

import HomeView from './HomeView';
import HeadmasterView from '../headmaster/HeadmasterView';

import { userTypesEnum } from 'src/utils/enums';

const Home = () => {
  const { user } = useUser();

  if (user.userType === userTypesEnum.DIRETOR) {
    return <HeadmasterView />;
  }

  return <HomeView />;
};

export default Home;
