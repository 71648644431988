import clsx from 'clsx';
import React from 'react';
import { useStyles } from './styles';

export default function FinalYears({ className }) {
  const classes = useStyles();

  return (
    <div>
      <svg
        className={clsx(classes.root, className)}
        viewBox='0 0 219 241'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M181.089 138.939C181.089 138.939 163.326 160.691 144.731 164.288'
          stroke='#DADEEB'
          strokeWidth='1.26741'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M70.9615 138.439L102.601 131.613C103.275 131.468 103.916 131.142 104.462 130.67C105.008 130.197 105.438 129.595 105.71 128.922C105.982 128.25 106.086 127.531 106.011 126.839C105.937 126.146 105.686 125.503 105.285 124.976L86.4203 100.208C86.0189 99.6795 85.4805 99.2847 84.8593 99.0633C84.2381 98.842 83.556 98.8019 82.8816 98.9471C82.2073 99.0924 81.5645 99.4178 81.018 99.8906C80.4716 100.363 80.0408 100.967 79.7689 101.64L66.9986 133.223C66.7244 133.896 66.6189 134.616 66.6927 135.311C66.7664 136.005 67.0169 136.65 67.4187 137.179C67.8206 137.708 68.3596 138.103 68.9816 138.324C69.6036 138.545 70.2865 138.585 70.9615 138.439Z'
          stroke='#DADEEB'
          strokeWidth='1.26741'
          strokeMiterlimit='10'
        />
        <path
          d='M163.342 112.119C163.342 112.119 161.774 120.212 158.521 124.677'
          stroke='#48AB96'
          strokeWidth='1.68988'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M133.387 185.796C133.387 185.796 129.978 192.743 130.913 198.452C131.847 204.16 133.395 208.138 129.519 213.074L112.923 193.743L122.031 178.621L133.387 185.796Z'
          fill='#48AB96'
        />
        <path
          d='M130.071 195.31L125.446 192.348'
          stroke='white'
          strokeWidth='1.42936'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M104.095 191.199C104.095 191.199 99.5599 197.838 99.6003 203.943C99.6408 210.048 100.573 214.46 95.8942 218.911L82.2266 195.902L93.793 181.74L104.095 191.199Z'
          fill='#48AB96'
        />
        <path
          d='M99.0754 201.546L94.2958 198.4'
          stroke='white'
          strokeWidth='1.49021'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M161.059 89.3153C161.059 89.3153 165.245 82.6508 163.945 79.3184C162.645 75.986 159.328 73.5453 157.216 73.2644C155.779 73.0775 153.798 73.1161 152.058 76.7685C150.317 80.4209 150.335 81.8541 150.335 81.8541C150.335 81.8541 149.649 87.2489 151.953 88.611C154.257 89.9732 159.118 91.6916 161.059 89.3153Z'
          fill='#EF71C3'
        />
        <path
          d='M156.737 73.6951C156.737 73.6951 155.927 75.3907 154.707 78.0648'
          stroke='#48AB96'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M159.821 75.4507C159.821 75.4507 158.526 77.8829 156.956 80.524C155.844 82.3896 153.764 82.3386 153.678 80.3958'
          stroke='#48AB96'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M161.986 77.9664C161.986 77.9664 160.204 81.2535 159.689 82.154C159.175 83.0546 158.348 83.5299 157.737 83.3111C156.785 82.9706 156.583 82.3621 156.669 81.5013'
          stroke='#48AB96'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M159.588 82.9078C159.588 82.9078 159.668 84.0408 160.065 84.3851C160.462 84.7294 161.384 85.1086 161.944 84.5074C162.416 83.9994 162.868 83.276 163.298 82.0739'
          stroke='#48AB96'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M153.93 84.3091C153.93 84.3091 156.12 83.151 156.369 80.5969C156.619 78.0428 155.151 78.9079 155.151 78.9079C155.151 78.9079 152.17 80.2817 151.292 80.8872C150.6 81.366 149.195 82.8928 149.465 84.1521C149.694 85.1652 150.127 86.0983 150.736 86.8922C150.947 87.1405 151.454 88.0286 152.898 87.8537C153.02 87.8373 155.063 87.1801 153.93 84.3091Z'
          fill='#EF71C3'
        />
        <path
          d='M101.212 133.311C101.212 133.311 99.3056 126.188 100.749 119.996L135.32 124.904L148.193 141.964C151.622 145.976 153.757 154.965 146.65 165.881C140.312 175.618 132.201 189.205 132.116 189.352C128.645 189.996 125.184 189.392 122.309 187.64C119.974 186.17 118.048 184.121 116.681 181.652C116.953 181.19 126.815 166.654 131.595 158.782C133.195 156.146 134.119 153.755 132.352 151.587C132.352 151.587 128.207 147.15 126.927 145.638C126.318 144.916 125.324 143.552 124.336 142.159C125.981 148.454 126.256 150.6 126.422 151.968C126.962 156.459 128.449 160.303 121.701 172.208C118.58 177.716 114.377 182.579 107.983 190.561C106.543 192.357 104.997 194.307 103.322 196.466C103.322 196.466 98.2861 195.594 94.3278 192.262C90.1009 188.713 89.8411 183.697 89.8411 183.697C107.07 165.305 108.398 161.942 108.459 155.265C108.488 152.499 103.976 141.942 101.212 133.311Z'
          fill='#0D0F44'
        />
        <path
          d='M123.737 141.757L125.78 138.918'
          stroke='white'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M106.48 192.858C106.48 192.858 97.4913 192.006 93.6713 183.234'
          stroke='white'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M135.583 186.987C135.583 186.987 129.634 188.218 124.936 184.2'
          stroke='white'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M100.563 88.9116C107.945 80.3678 119.487 77.1052 126.151 77.4174C129.579 77.2544 132.875 78.1569 135.625 80.0109C143.563 83.6636 147.344 95.5837 147.344 95.5837C148.772 92.3433 150.856 87.379 151.514 85.8089C151.651 85.4775 151.899 85.2004 152.207 85.0331C153.293 84.4503 155.97 83.2414 157.977 84.2696C159.31 84.9692 160.401 86.0524 161.135 87.4049C161.358 87.8149 161.448 88.2988 161.395 88.792C158.193 117.655 153.593 122.925 149.133 124.117C144.921 125.244 143.246 121.999 141.031 118.945C140.436 121.089 139.747 123.343 138.937 125.724C138.937 125.724 137.564 130.433 133.184 131.155C123.918 132.679 115.598 134.323 97.8162 123.026C97.8162 123.026 97.8033 121.193 98.0925 118.213C96.7347 118.054 95.459 117.572 94.3606 116.803C93.2621 116.033 92.3693 114.996 91.7487 113.769C88.9418 109.65 92.2193 98.5677 100.563 88.9116Z'
          fill='#48AB96'
        />
        <path
          d='M110.797 88.7779C110.797 88.7779 106.486 96.4 106.018 99.0242'
          stroke='white'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M141.659 90.8115C141.659 90.8115 145.433 97.0103 141.724 117.109'
          stroke='white'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M101.423 103.938C100.678 103.121 101.381 101.254 102.993 99.7684C104.605 98.2824 106.516 97.74 107.261 98.557C108.006 99.3739 107.304 101.241 105.691 102.727C104.079 104.213 102.168 104.755 101.423 103.938Z'
          fill='#EF71C3'
        />
        <path
          d='M107.027 38.2902C107.027 38.2902 102.126 45.269 102.933 49.7243C103.577 53.2717 100.924 51.8572 100.924 51.8572C100.924 51.8572 98.0109 50.8383 101.135 44.1941C103.958 38.1905 107.027 38.2902 107.027 38.2902Z'
          fill='#FFD5B8'
        />
        <path
          d='M132.653 20.1691C131.899 19.0667 130.574 18.6879 129.215 18.8376C126.594 19.126 120.807 20.3539 118.003 21.2573C114.253 22.4644 111.222 23.4953 108.323 24.8243C106.543 25.642 104.119 28.0174 104.006 31.0986C103.851 35.2818 104.097 41.5558 108.878 46.0663C110.223 47.3327 112.797 47.7307 114.61 46.9328C120.05 44.5484 130.42 39.3991 133.725 34.5777C134.054 34.0936 134.249 33.5326 134.285 32.9644C134.428 30.792 135.15 23.8135 132.653 20.1691Z'
          fill='#48AB96'
        />
        <path
          d='M130.968 39.4474C130.968 39.4474 131.459 36.5586 128.788 31.3501C128.298 30.3945 127.417 29.5947 126.337 29.6379C123.949 29.7553 119.557 30.6585 113.759 36.1817C113.324 36.6108 112.999 37.1451 112.819 37.7267C112.639 38.3082 112.61 38.9148 112.736 39.4809C113.158 41.4677 114.084 43.8825 118.939 47.899L130.968 39.4474Z'
          fill='#48AB96'
        />
        <path
          d='M118.996 47.48C118.34 46.3306 120.577 43.7339 123.991 41.6801C127.404 39.6263 130.703 38.8931 131.358 40.0425C132.014 41.1918 129.777 43.7886 126.364 45.8424C122.95 47.8962 119.651 48.6294 118.996 47.48Z'
          fill='#0D0F44'
        />
        <path
          d='M131.912 50.5218C131.912 50.5218 128.555 51.3624 126.876 55.8214C126.876 55.8214 125.821 54.8438 124.696 55.0965C122.937 55.4795 120.754 58.8596 121.113 63.5086C121.113 63.5086 120.478 70.7738 123.835 69.9031L135.619 62.7959L131.912 50.5218Z'
          fill='#0D0F44'
        />
        <path
          d='M133.748 69.765C132.293 74.1672 132.472 81.5224 132.472 81.5224C132.472 81.5224 128.21 83.9734 121.502 79.3428C121.502 79.3428 122.899 75.0543 123.372 73.7686C125.412 68.2164 127.019 66.5302 126.689 67.5124L133.748 69.765Z'
          fill='#EF71C3'
        />
        <path
          d='M132.601 76.802C132.601 76.802 126.6 76.2263 124.56 70.294C124.645 70.1122 124.726 69.9395 124.807 69.7744L133.01 73.0307C132.811 74.296 132.684 75.5975 132.601 76.802Z'
          fill='#0D0F44'
        />
        <path
          d='M128.665 74.2473C124.134 72.6393 122.073 67.3536 124.062 62.4412C126.051 57.5288 131.337 54.8501 135.868 56.4581C140.399 58.066 142.46 63.3518 140.471 68.2641C138.483 73.1765 133.197 75.8552 128.665 74.2473Z'
          fill='#EF71C3'
        />
        <path
          d='M123.142 64.8625C123.217 66.1029 123.797 66.1892 124.09 65.9012C124.898 65.0989 124.887 64.1481 126.598 61.1926C127.174 60.2269 128.047 59.479 129.056 59.0875C132.098 57.7953 135.438 59.5582 135.502 59.5933C136.349 59.9526 137.059 60.5708 137.737 61.0835C143.175 65.2015 145.116 60.9816 145.871 59.1193C147.146 55.9687 145.887 52.598 143.05 51.5915C142.154 51.2799 141.173 51.2285 140.203 51.4423C139.234 51.656 138.31 52.1276 137.526 52.8097C137.049 51.6608 136.145 50.7931 135.004 50.3881C132.514 49.5047 129.578 51.0331 128.456 53.8032C128.121 54.6256 127.974 55.5016 128.028 56.3563C126.574 56.7819 125.322 58.4753 124.866 59.1432C123.906 60.5456 123.052 63.3572 123.142 64.8625Z'
          fill='#0D0F44'
        />
        <path
          d='M131.238 63.773C131.436 63.0639 131.853 62.5464 132.17 62.6171C132.486 62.6878 132.582 63.3199 132.384 64.029C132.186 64.7381 131.768 65.2556 131.452 65.1849C131.136 65.1142 131.04 64.4821 131.238 63.773Z'
          fill='#0D0F44'
        />
        <path
          d='M135.88 64.9349C136.078 64.2258 136.495 63.7083 136.811 63.779C137.128 63.8497 137.224 64.4818 137.025 65.1909C136.827 65.9 136.41 66.4175 136.094 66.3468C135.777 66.2761 135.681 65.6439 135.88 64.9349Z'
          fill='#0D0F44'
        />
        <path
          d='M129.742 69.1881C129.742 69.1881 127.966 69.3006 128.175 67.0132'
          stroke='#0D0F44'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M121.532 65.6541C120.675 64.3067 120.787 62.6797 121.782 62.0199C122.777 61.3602 124.279 61.9177 125.136 63.2651C125.994 64.6124 125.882 66.2395 124.886 66.8992C123.891 67.5589 122.389 67.0015 121.532 65.6541Z'
          fill='#EF71C3'
        />
        <path
          d='M124.103 65.2582L122.093 63.2195'
          stroke='#0D0F44'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M134.097 64.7561L133.646 67.3637C133.633 67.4394 133.605 67.5129 133.565 67.5795C133.525 67.6462 133.472 67.7044 133.412 67.7507C133.351 67.7969 133.283 67.8301 133.212 67.8481C133.141 67.8661 133.069 67.8686 133 67.8553L132.435 67.7582'
          stroke='#0D0F44'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M77.0965 57.4822L71.6778 59.4759C71.3896 59.5833 71.0868 59.6181 70.7956 59.5773C70.5045 59.5366 70.2337 59.4215 70.0066 59.2419L65.7553 55.856C65.4949 55.6476 65.1768 55.525 64.8371 55.502C64.4974 55.479 64.1497 55.5566 63.8333 55.726C63.517 55.8953 63.2447 56.1496 63.0473 56.4601C62.85 56.7706 62.7355 57.1248 62.7167 57.4825L62.4082 63.3131C62.392 63.6237 62.3034 63.9324 62.1502 64.2125C61.997 64.4925 61.7838 64.7356 61.5289 64.9207L56.7422 68.395C56.4488 68.6077 56.2112 68.8967 56.0564 69.2291C55.9015 69.5616 55.8356 69.9243 55.866 70.2762C55.8964 70.6281 56.0219 70.9552 56.2284 71.2204C56.4349 71.4856 56.714 71.6784 57.0343 71.7769L62.2598 73.3865C62.5385 73.4719 62.7868 73.629 62.9832 73.844C63.1796 74.059 63.3183 74.3255 63.3872 74.6205L64.6844 80.1479C64.7652 80.4846 64.9369 80.7825 65.1802 81.0085C65.4234 81.2344 65.7287 81.3794 66.0617 81.4271C66.3948 81.4748 66.7425 81.4235 67.0658 81.2788C67.3892 81.1341 67.6755 80.9017 67.8926 80.6078L71.4467 75.7607C71.6357 75.5034 71.8777 75.2922 72.1521 75.145C72.4265 74.9977 72.7252 74.9189 73.0227 74.9151L78.5952 74.872C78.9376 74.8692 79.2812 74.7663 79.5869 74.5748C79.8926 74.3834 80.1482 74.1112 80.3249 73.7889C80.5015 73.4667 80.5921 73.1073 80.5864 72.7516C80.5807 72.3958 80.4788 72.0579 80.2924 71.776L77.2551 67.1767C77.0928 66.9322 76.9941 66.6446 76.9676 66.3388C76.941 66.033 76.9874 65.7183 77.1028 65.422L79.2731 59.8609C79.4071 59.5187 79.4491 59.1528 79.3943 58.8049C79.3395 58.457 79.1901 58.141 78.9632 57.893C78.7362 57.645 78.4409 57.475 78.1109 57.4024C77.7808 57.3297 77.4293 57.3574 77.0965 57.4822Z'
          fill='#FFD5B8'
        />
        <path
          d='M177.905 89.5907L203.403 107.552C203.637 107.718 203.906 107.83 204.193 107.881C204.479 107.931 204.779 107.919 205.073 107.845C205.367 107.772 205.649 107.638 205.903 107.452C206.157 107.266 206.378 107.032 206.551 106.764L220.747 84.8334C220.924 84.5602 221.049 84.257 221.114 83.9429C221.179 83.6288 221.182 83.3104 221.123 83.0076C221.065 82.7048 220.946 82.4241 220.773 82.1828C220.601 81.9415 220.38 81.7449 220.122 81.6053L192.659 66.6798C192.184 66.4195 191.614 66.3683 191.064 66.5366C190.515 66.7049 190.027 67.08 189.699 67.586L177.474 86.4825C177.14 86.998 176.998 87.6099 177.078 88.1896C177.158 88.7692 177.455 89.2715 177.905 89.5907Z'
          fill='#20D2EF'
        />
        <path
          d='M177.245 86.2101C177.257 86.1779 177.27 86.1458 177.299 86.1166L177.363 86.0345C177.39 86.0443 179.639 87.8394 185.522 84.6652C192.121 81.1088 195.726 82.8828 198.128 84.8251C198.79 85.3873 199.356 86.0636 199.803 86.8298C201.04 86.1345 202.408 85.8159 203.74 85.9128C205.909 86.0249 208.657 87.9675 211.908 91.6897C212.935 92.8608 213.846 94.0326 214.562 95.0033L214.438 95.1926C212.046 91.9729 207.345 86.311 203.71 86.1252C202.423 86.0316 201.101 86.3371 199.903 87.0048C201.17 89.1892 201.939 92.2454 201.369 94.8828C200.901 97.0524 199.595 98.6041 197.588 99.372C197.089 99.586 196.549 99.5934 196.083 99.3926C195.93 99.3206 195.789 99.2251 195.665 99.1088C194.618 98.1325 194.513 95.7892 194.945 93.9794C195.392 92.2128 196.199 90.5352 197.307 89.0689C197.955 88.2182 198.742 87.4939 199.626 86.937C199.191 86.1975 198.645 85.544 198.006 84.9992C195.653 83.0932 192.105 81.3525 185.594 84.8511C179.73 88.0114 177.445 86.3684 177.245 86.2101ZM197.449 89.188C196.358 90.628 195.565 92.2764 195.126 94.0127C194.726 95.7078 194.827 98.0289 195.798 98.9438C196.247 99.3671 196.832 99.44 197.531 99.1731C198.438 98.8632 199.265 98.2906 199.913 97.5244C200.561 96.7582 201.002 95.8312 201.183 94.8557C201.746 92.2603 200.983 89.2506 199.737 87.1081C198.865 87.6469 198.087 88.3539 197.449 89.188Z'
          fill='white'
        />
        <path
          d='M104.03 124.868L130.586 129.308C131.589 129.476 132.654 129.231 133.565 128.622C134.476 128.014 135.164 127.088 135.491 126.031L141.478 106.689C142.118 104.616 141.015 102.763 139.187 102.013C135.387 100.456 131.034 98.7405 125.553 97.3896C120.277 96.091 116.198 95.3676 113.037 94.8675C109.321 94.2778 107.189 95.9646 106.256 98.9815L100.322 118.158C100.092 118.9 100.007 119.668 100.071 120.415C100.135 121.163 100.348 121.873 100.695 122.502C101.043 123.131 101.518 123.666 102.092 124.073C102.666 124.48 103.325 124.75 104.03 124.868Z'
          fill='white'
        />
        <path
          d='M122.605 122.061L125.112 120.379L127.267 121.925C127.429 119.057 127.292 116.21 126.856 113.434L122.256 114.74C122.623 117.135 122.739 119.589 122.605 122.061ZM122.256 114.74L124.558 114.082L122.257 114.738L122.256 114.74Z'
          fill='#48AB96'
        />
        <path
          d='M117.101 120.256C119.327 117.041 121.183 113.159 121.261 112.995L117.132 111.179C117.117 111.217 115.393 114.824 113.428 117.656L116.25 117.549L117.101 120.256Z'
          fill='#48AB96'
        />
        <path
          d='M126.724 113.437C126.737 113.503 126.853 114.191 126.969 115.274C125.585 116.308 123.95 116.848 122.335 116.804C122.223 115.597 122.092 114.807 122.083 114.76L124.404 114.1L126.724 113.437Z'
          fill='#0D0F44'
        />
        <path
          d='M117.05 111.22L121.267 113.07C121.229 113.165 120.621 114.42 119.707 116.082C118.192 115.482 116.97 114.339 116.24 112.84C116.734 111.883 117.043 111.235 117.05 111.22Z'
          fill='#0D0F44'
        />
        <path
          d='M116.289 106.775C117.616 103.091 121.652 101.009 125.304 102.125C128.956 103.241 130.842 107.132 129.515 110.816C128.188 114.499 124.152 116.581 120.499 115.465C116.847 114.349 114.962 110.458 116.289 106.775Z'
          fill='#48AB96'
        />
        <path
          d='M123.447 105.639L122.447 106.87C122.393 106.935 122.326 106.987 122.252 107.021C122.179 107.056 122.099 107.073 122.022 107.07L120.561 106.999C120.471 106.995 120.38 107.017 120.298 107.064C120.215 107.11 120.144 107.179 120.093 107.261C120.042 107.344 120.013 107.438 120.009 107.532C120.004 107.626 120.026 107.717 120.07 107.795L120.789 109.066C120.827 109.133 120.849 109.211 120.851 109.292C120.853 109.374 120.836 109.456 120.801 109.533L120.149 110.976C120.109 111.064 120.093 111.16 120.102 111.253C120.111 111.346 120.145 111.431 120.2 111.5C120.255 111.569 120.329 111.619 120.413 111.643C120.498 111.668 120.59 111.666 120.679 111.639L122.126 111.189C122.203 111.165 122.282 111.161 122.357 111.177C122.433 111.192 122.501 111.227 122.558 111.279L123.616 112.238C123.681 112.297 123.762 112.334 123.85 112.345C123.938 112.355 124.03 112.34 124.115 112.3C124.2 112.26 124.275 112.197 124.332 112.118C124.388 112.039 124.424 111.947 124.435 111.853L124.607 110.313C124.615 110.231 124.643 110.149 124.687 110.076C124.732 110.004 124.792 109.942 124.862 109.896L126.181 109.04C126.261 108.988 126.327 108.915 126.372 108.829C126.417 108.743 126.44 108.648 126.437 108.555C126.435 108.461 126.407 108.373 126.358 108.3C126.308 108.226 126.239 108.171 126.157 108.14L124.816 107.634C124.745 107.606 124.683 107.56 124.636 107.499C124.589 107.438 124.558 107.364 124.546 107.284L124.294 105.797C124.279 105.704 124.239 105.619 124.177 105.553C124.115 105.488 124.035 105.444 123.946 105.427C123.856 105.411 123.762 105.422 123.673 105.459C123.585 105.497 123.506 105.559 123.447 105.639Z'
          fill='#FFD5B8'
        />
        <path
          d='M137.807 104.316C137.807 104.316 140.7 104.983 139.875 107.706L139.058 110.437'
          stroke='#DADEEB'
          strokeWidth='1.26741'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M114.876 97.106C114.876 97.106 111.393 96.1895 108.886 96.9491'
          stroke='#DADEEB'
          strokeWidth='1.26741'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M109.184 104.899C109.184 104.899 109.826 101.446 105.947 101.427C102.068 101.409 99.8561 103.424 99.4023 104.583C99.1862 105.119 94.8654 114.917 97.2548 116.705C98.2761 117.47 103.326 118.828 106.154 114.586C108.983 110.345 109.184 104.899 109.184 104.899Z'
          fill='#EF71C3'
        />
        <path
          d='M108.631 104.489C108.631 104.489 105.904 103.974 104.181 104.67'
          stroke='#48AB96'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M108.279 107.467C108.279 107.467 105.552 106.952 103.829 107.649'
          stroke='#48AB96'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M107.645 110.061C107.645 110.061 104.908 109.541 103.189 110.237'
          stroke='#48AB96'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M106.512 113.077C106.512 113.077 104.462 112.341 102.736 113.039'
          stroke='#48AB96'
          strokeWidth='0.844939'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M125.805 30.3724C124.158 30.7174 117.305 32.6261 114.387 39.2472C113.975 40.2789 113.243 42.7889 114.977 43.8841L116.566 45.3406'
          stroke='white'
          strokeLinecap='round'
        />
      </svg>
    </div>
  );
}
