import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import useParameter from 'src/hooks/useParameter';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
    },
  },
  video: {
    borderRadius: 9,
  },
}));

export default function PromotionalVideo() {
  const classes = useStyles();
  const { getParameterByName, variables } = useParameter();
  const PARAMETER_NAME = 'VIDEO_PITCH';

  const [videoLink, setVideoLink] = useState('');
  const [loading, setLoading] = useState(false);

  async function fetchVideoLink() {
    setLoading(true);

    await getParameterByName(PARAMETER_NAME);

    setLoading(false);
  }

  useEffect(() => {
    fetchVideoLink();
  }, []);

  useEffect(() => {
    if (Object.keys(variables).length > 0 && variables[PARAMETER_NAME]) {
      setVideoLink(variables[PARAMETER_NAME].value);
    }
  }, [variables]);

  return (
    <Card className={classes.root}>
      {loading ? (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          minHeight={320}
        >
          <Box mb={3}>
            <Typography variant='subtitle1'>Carregando video...</Typography>
          </Box>

          <CircularProgress color='primary' />
        </Box>
      ) : (
        <iframe
          width='100%'
          height='320'
          className={classes.video}
          src={videoLink}
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      )}
    </Card>
  );
}
