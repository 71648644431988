import { Chip } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  BookOpen as BookOpenIcon,
  Calendar as CalendarIcon,
  FileText as FileTextIcon,
  HelpCircle as HelpCircleIcon,
  Home as HomeIcon,
  Mail as MailIcon,
  Sliders as SlidersIcon,
  User as UserIcon,
  Archive,
  Book as BookIcon,
  Database as DatabaseIcon,
  FilePlus as FilePlusIcon,
  PieChart as PieChartIcon,
  Settings as GearIcon,
  Clipboard as ClipboardIcon,
} from 'react-feather';

// Super Admin
export const sections = [
  {
    items: [
      {
        title: 'Página inicial',
        icon: HomeIcon,
        href: '/app',
      },
      {
        title: 'Criar plano de aula',
        icon: FileTextIcon,
        href: '/app/lessonplan',
      },
      {
        title: 'Planejamento',
        href: '/app/calendar',
        icon: CalendarIcon,
      },
      {
        title: 'Usuários',
        icon: UserIcon,
        href: '/app/users/view',
      },
      {
        title: 'Instituição',
        icon: BookOpenIcon,
        href: '/app/institution',
      },
      {
        title: 'Materiais',
        icon: BookIcon,
        href: '/app/publishing',
      },
      {
        title: 'Diário Online',
        icon: Archive,
        href: '/app/class',
      },
      {
        title: 'FAQ',
        icon: HelpCircleIcon,
        href: '/app/faq',
      },
      {
        subheader: 'Configurações',
        title: 'Configurações',
        icon: GearIcon,
        items: [
          {
            title: 'ParÃ¢metros',
            icon: SlidersIcon,
            href: '/app/parameters',
          },
          {
            title: 'Modelos de email',
            icon: MailIcon,
            href: '/app/mail-marketing',
          },
        ],
      },
      {
        subheader: 'Aprova',
        title: 'Aprova',
        icon: BookIcon,
        items: [
          {
            title: 'Escolas',
            icon: BookIcon,
            href: '/app/school/view',
          },
          {
            title: 'Provas',
            icon: FilePlusIcon,
            href: '/app/newassessments',
          },
          {
            title: 'Provas preenchidas',
            icon: DatabaseIcon,
            href: '/app/assessments',
          },
          {
            title: 'Relatório',
            icon: ClipboardIcon,
            href: '/app/reports/dashboard-alternative',
          },
        ],
      },
    ],
  },
];

// Admin
export const adminSections = [
  {
    items: [
      {
        title: 'Página inicial',
        icon: HomeIcon,
        href: '/app',
      },
      {
        title: 'Criar plano de aula',
        icon: FileTextIcon,
        href: '/app/lessonplan',
      },
      {
        title: 'Usuários',
        icon: UserIcon,
        href: '/app/users/view',
      },
      {
        title: 'Instituição',
        icon: BookOpenIcon,
        href: '/app/institution',
      },
      {
        title: 'Materiais',
        icon: BookIcon,
        href: '/app/publishing',
      },
      {
        title: 'Diário Online',
        icon: Archive,
        href: '/app/class',
      },
      {
        title: 'FAQ',
        icon: HelpCircleIcon,
        href: '/app/faq',
      },
      {
        subheader: 'Configurações',
        title: 'Configurações',
        items: [
          {
            title: 'Parâmetros',
            icon: SlidersIcon,
            href: '/app/parameters',
          },
          {
            title: 'Modelos de email',
            icon: MailIcon,
            href: '/app/mail-marketing',
          },
        ],
      },
      {
        subheader: 'Aprova',
        title: 'Aprova',
        icon: BookIcon,
        items: [
          {
            title: 'Escolas',
            icon: BookIcon,
            href: '/app/school/view',
          },
          {
            title: 'Provas',
            icon: FilePlusIcon,
            href: '/app/newassessments',
          },
          {
            title: 'Provas preenchidas',
            icon: DatabaseIcon,
            href: '/app/assessments',
          },
        ],
      },
    ],
  },
];

// Users General - Professors
export const sectionsInstitutionalUsers = [
  {
    items: [
      {
        title: 'Página inicial',
        icon: HomeIcon,
        href: '/app',
      },
      {
        title: 'Planos de aula',
        icon: FileTextIcon,
        href: '/app/lessonplan/categories',
      },
      {
        title: 'Materiais',
        icon: BookIcon,
        href: '/app/publishingUser/',
      },
      {
        title: 'Diário Online',
        icon: Archive,
        href: '/app/class',
      },
      {
        title: 'Planejamento',
        href: '/app/calendar',
        icon: CalendarIcon,
      },
      {
        title: 'FAQ',
        icon: HelpCircleIcon,
        href: '/app/faq',
      },
    ],
  },
];

// Users General - Professors com permissÃ£o ao LEC
export const sectionsInstitutionalUsersWithPermissionApprove = [
  {
    items: [
      {
        title: 'Página inicial',
        icon: HomeIcon,
        href: '/app',
      },
      {
        title: 'Planos de aula',
        icon: FileTextIcon,
        href: '/app/lessonplan/categories',
      },
      {
        title: 'Materiais',
        icon: BookIcon,
        href: '/app/publishingUser/',
      },
      {
        title: 'Diário Online',
        icon: Archive,
        href: '/app/class',
      },
      {
        title: 'Planejamento',
        href: '/app/calendar',
        icon: CalendarIcon,
      },
      {
        title: 'FAQ',
        icon: HelpCircleIcon,
        href: '/app/faq',
      },
      {
        subheader: 'Aprova',
        title: 'Aprova',
        icon: BookIcon,
        items: [
          {
            title: 'Escolas',
            icon: BookIcon,
            href: '/app/school/view',
          },
          {
            title: 'Provas',
            icon: FilePlusIcon,
            href: '/app/newassessments',
          },
        ],
      },
    ],
  },
];

export const sectionsInstitutionalUsersWithPermissionAdminApprove = [
  {
    items: [
      {
        title: 'Página inicial',
        icon: HomeIcon,
        href: '/app',
      },
      {
        title: 'Planos de aula',
        icon: FileTextIcon,
        href: '/app/lessonplan/categories',
      },
      {
        title: 'Materiais',
        icon: BookIcon,
        href: '/app/publishingUser/',
      },
      {
        title: 'Diário Online',
        icon: Archive,
        href: '/app/class',
      },
      {
        title: 'Planejamento',
        href: '/app/calendar',
        icon: CalendarIcon,
      },
      {
        title: 'FAQ',
        icon: HelpCircleIcon,
        href: '/app/faq',
      },
      {
        subheader: 'Aprova',
        title: 'Aprova',
        icon: BookIcon,
        items: [
          {
            title: 'Escolas',
            icon: BookIcon,
            href: '/app/school/view',
          },
          {
            title: 'Provas',
            icon: FilePlusIcon,
            href: '/app/newassessments',
          },
          {
            title: 'Provas preenchidas',
            icon: DatabaseIcon,
            href: '/app/assessments',
          },
          {
            title: 'Relatório',
            icon: ClipboardIcon,
            href: '/app/reports/dashboard-alternative',
          },
        ],
      },
    ],
  },
];

export const sectionsHeadmasterUsers = [
  {
    items: [
      {
        title: 'Página inicial',
        icon: HomeIcon,
        href: '/app',
      },
      {
        title: 'Usuários',
        icon: UserIcon,
        href: '/app/users/view',
      },
      {
        title: 'Instituição',
        icon: BookOpenIcon,
        href: '/app/institution',
      },
      {
        title: 'Diário Online',
        icon: Archive,
        href: '/app/classes',
      },
    ],
  },
];
