import _ from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';
import { formatISO, parseISO, isDate } from 'date-fns';

import api from 'src/services/api';
import { formatDate } from 'src/utils/formatDate';
import { handleOpenFormModal } from './calendar';

const initialState = {
  annotations: [],
  selectedAnnotation: {},
};

const slice = createSlice({
  name: 'annotations',
  initialState,
  reducers: {
    getAnnotations(state, action) {
      state.annotations = action.payload;
    },

    createAnnotation(state, action) {
      const annotation = action.payload;

      const annotations = current(state.annotations);

      state.annotations = annotations.concat({
        ...annotation,
        date: formatDate(JSON.parse(annotation.date), 'yyyy-MM-dd'),
        start: JSON.parse(annotation.start),
        end: JSON.parse(annotation.end),
      });
    },

    selectAnnotation(state, action) {
      state.selectedAnnotation = action.payload;
    },

    updateAnnotation(state, action) {
      const annotation = action.payload;

      state.annotations = _.map(current(state.annotations), ant => {
        if (ant.id === annotation.id) {
          return {
            id: annotation.id,
            title: annotation.title,
            date: formatDate(JSON.parse(annotation.date), 'yyyy-MM-dd'),
            start: JSON.parse(annotation.start),
            end: JSON.parse(annotation.end),
          };
        }

        return ant;
      });
    },

    deleteAnnotation(state, action) {
      const { annotationId } = action.payload;

      state.annotations = _.filter(
        current(state.annotations),
        _event => _event.id !== annotationId
      );
      state.selectedAnnotation = {};
    },

    resetSelectedAnnotation(state, action) {
      state.selectedAnnotation = {};
    },
  },
});

export const reducer = slice.reducer;

export const getAnnotations = () => async dispatch => {
  const { data } = await api.get('/agenda/annotations');

  if (!data) return;

  const formattedAnnotations = data
    .map(annotation => ({
      id: annotation.uuid,
      title: annotation.title,
      date: annotation.agenda.date,
      start: formatISO(
        new Date(`${annotation.agenda.date} ${annotation.hourInitial}`)
      ),
      end: formatISO(
        new Date(`${annotation.agenda.date} ${annotation.hourFinal}`)
      ),
    }))
    .reduce((list, sub) => list.concat(sub), []);

  dispatch(slice.actions.getAnnotations(formattedAnnotations));
};

export const createAnnotation = body => async dispatch => {
  const formData = {
    data: formatDate(body.date, 'yyyy-MM-dd'),
    valor: {
      ...body,
      hourInitial: formatDate(new Date(body.hourInitial), 'HH:mm'),
      hourFinal: formatDate(new Date(body.hourFinal), 'HH:mm'),
    },
  };

  await api.post('/agenda/annotations', formData);

  // const payload = {
  //   id: body.uuid,
  //   title: body.title,
  //   date: JSON.stringify(data),
  //   start: JSON.stringify(body.hourInitial),
  //   end: JSON.stringify(body.hourFinal)
  // };

  // dispatch(slice.actions.createAnnotation(payload));
  dispatch(getAnnotations());
};

export const selectAnnotation = (
  annotationId,
  dateSelected
) => async dispatch => {
  const { data } = await api.get(`/agenda/annotations/${annotationId}`);

  if (!data) return;

  const formattedDateSelected = formatDate(dateSelected, 'yyyy-MM-dd');

  const formattedAnnotations = {
    ...data,
    id: data.uuid,
    title: data.title,
    hourInitial: formatISO(
      new Date(`${formattedDateSelected} ${data.hourInitial}`)
    ),
    hourFinal: formatISO(
      new Date(`${formattedDateSelected} ${data.hourFinal}`)
    ),
  };

  dispatch(slice.actions.selectAnnotation(formattedAnnotations));

  return dispatch(handleOpenFormModal());
};

export const resetSelectedAnnotation = () => dispatch => {
  return dispatch(slice.actions.resetSelectedAnnotation());
};

export const updateAnnotation = (annotationId, body) => async dispatch => {
  const formData = {
    ...body,
    hourInitial: formatDate(new Date(body.hourInitial), 'HH:mm'),
    hourFinal: formatDate(new Date(body.hourFinal), 'HH:mm'),
  };

  await api.put(`/agenda/annotations/${annotationId}`, formData);

  const payload = {
    title: formData.title,
    id: annotationId,
    date: JSON.stringify(body.date),
    start: JSON.stringify(body.hourFinal),
    end: JSON.stringify(body.hourFinal),
  };

  dispatch(slice.actions.updateAnnotation(payload));
};

export const deleteAnnotation = annotationId => async dispatch => {
  await api.delete(`/agenda/annotations/${annotationId}`);

  return dispatch(slice.actions.deleteAnnotation({ annotationId }));
};

export default slice;
