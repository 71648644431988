import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import MeetingRoomRoundedIcon from '@material-ui/icons/MeetingRoomRounded';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import useUser from 'src/hooks/useUser';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    // '&:focus': {
    //   backgroundColor: theme.palette.primary.main,
    //   '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //     color: theme.palette.common.white,
    //   },
    // },
    '&:hover': {
      borderRadius: '16px',
    },
  },
}))(MenuItem);

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
  username: {
    color: '#fff',
  },
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { logout } = useAuth();
  const { user } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();

      logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar alt='User' className={classes.avatar} src={user.imageUrl} />
        <Hidden smDown>
          <Typography variant='h5' className={classes.username}>
            {user.name}
          </Typography>
        </Hidden>
      </Box>
      <StyledMenu
        id='customized-menu'
        anchorEl={ref.current}
        keepMounted
        open={isOpen}
        onClose={handleClose}
        PaperProps={{ className: classes.popover }}
      >
        {/* <StyledMenuItem
          component={RouterLink}
          to="/app/social/profile">
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Perfil" />
        </StyledMenuItem> */}
        <StyledMenuItem component={RouterLink} to='/app/account'>
          <ListItemIcon>
            <AssignmentIndIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Perfil' />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleLogout}>
          <ListItemIcon>
            <MeetingRoomRoundedIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Sair' />
        </StyledMenuItem>
      </StyledMenu>
      {/* <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          component={RouterLink}
          to="/app/social/profile"
        >
          Profile
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/app/account"
        >
          Account
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu> */}
    </>
  );
};

export default Account;
